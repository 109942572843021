import React, { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Footer from '../Includes/Footer'
import { useFormik } from 'formik';
import { passwordProfile, passwordSchema } from '../../schema';
import GeneralService from '../../services/general.service';
import secureLocalStorage from 'react-secure-storage';
import { toast } from 'react-toastify';

export default function ResetPassword() {
   const navigate = useNavigate();
   const [isLoading, setIsLoading] = useState(false)
   const [isEnable, setIsEnable] = useState(true)
   const location = useLocation();
   const { otp, email } = location.state || {};
// console.log(location.state);
   const updatePassword = async (values) => {

      // console.log(values);
      try {
         // let userId = await AsyncStorage.getItem("_id");
         // console.log(otp, password, values.password);
         setIsLoading(true);
         setIsEnable(false);
         console.log(values.password);
         const timeout = 8000;
         const response = await Promise.race([
            GeneralService.updatePassword(otp, email, values.password),
            new Promise((_, reject) => setTimeout(() => reject(new Error('Request timeout')), timeout))
         ]);

         if (response) {
            // toast.success('Information updated successfully');
            navigate("/");
         } else {
            throw new Error('No response from the server');
         }
         // const response = await GeneralService.updateUserById(values.name, values.address, values.phone, values.email, userId);
         // console.log('SignUp response:', response);
         setIsLoading(false);
         setIsEnable(true);
         // Alert.alert('Success', 'User registered successfully');
      } catch (err) {
         console.log(err);
         setIsLoading(false);
         setIsEnable(true);
         // setEmailError("");
         // setPhoneError("");
         toast.error("Server error. Please try again later.");
      }
   };


   const { values, errors, touched, handleBlur, handleChange, handleSubmit, resetForm } = useFormik({
      enableReinitialize: true,
      initialValues: {
         password: "",
         confirm_password: "",
      },
      validationSchema: passwordSchema,
      onSubmit: async (values, actions) => {
         try {
            const userId = secureLocalStorage.getItem("id");
            await updatePassword(values);
            toast.success("Password updated");
            // console.log('Sign up successful', response.data);
            // toast.success("Sign up successful!"); // Display success message
            resetForm(); // Reset the form fields
         } catch (error) {
            console.error('Sign up failed', error.response ? error.response.data : error.message);
            if (error?.response?.status === 422) {
               toast.error("Current Password is incorrect");
            } else {
               toast.error("Server error. Please try again later.");
            }
            // toast.error('Sign up failed. Please try again.'); // Show error message
         } finally {
            actions.setSubmitting(false);
         }
      },
   });

   return (
      <>
         <div className="osahan-header-main py-3 px-4">
            <div className="d-flex align-items-center">
               <div className="gap-3 d-flex align-items-center">
                  <Link to="/">
                     <i className="bi bi-arrow-left d-flex text-success h3 m-0 back-page"></i>
                  </Link>
                  <h3 className="fw-bold m-0">Change Password</h3>
               </div>
            </div>
         </div>
         <div className="mx-4 mb-4 bg-white rounded-4 shadow overflow-hidden p-4">
            <form noValidate onSubmit={handleSubmit}>
               <div className="mb-3">
                  <label className="form-label text-muted">Password</label>
                  <input
                     id="password"
                     name="password"
                     type="password"
                     className="shadow-none form-control"
                     onChange={handleChange}
                     value={values.password}
                     placeholder="Enter Password" />
                  {touched.password && errors.password && <p style={{ color: "red" }}>{errors.password}</p>}
               </div>
               <div className="mb-3">
                  <label className="form-label text-muted">Confirm Password</label>
                  <input
                     id="confirm_password"
                     name="confirm_password"
                     type="password"
                     className="shadow-none form-control"
                     onChange={handleChange}
                     value={values.confirm_password}
                     placeholder="Enter Confirm Password" />
                  {touched.confirm_password && errors.confirm_password && <p style={{ color: "red" }}>{errors.confirm_password}</p>}
               </div>

               <div className="d-grid">
                  <button type="submit" className="btn btn-success btn-lg rounded-pill">
                     Update
                  </button>
               </div>
            </form>
         </div>
         {/* <Footer /> */}
      </>
   )
}
