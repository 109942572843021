import React from 'react';
import './VerticalStepper.css';

const COLORS = {
    primary: '#3498db', // You can customize these colors
    gray4: '#bdc3c7',
    black: '#2c3e50'
};

export default function VerticalStepper({ status }) {
    let packingColor = (status === "PACKING" || status === "TRANSIT" || status === "DELIVERED") ? COLORS.primary : COLORS.gray4;
    let transitColor = (status === "TRANSIT" || status === "DELIVERED") ? COLORS.primary : COLORS.gray4;
    let deliveredColor = (status === "DELIVERED") ? COLORS.primary : COLORS.gray4;

    return (
        <div className="status-container">
            <div className="step-container">
                <div className="step-indicator" style={{ backgroundColor: COLORS.primary }} />
                <span className="step-text">Your order has been received</span>
            </div>

            <div className="step-container">
                <div className="step-indicator" style={{ backgroundColor: packingColor }} />
                <span className="step-text">The order is being packed</span>
            </div>

            <div className="step-container">
                <div className="step-indicator" style={{ backgroundColor: transitColor }} />
                <span className="step-text">Your order has been picked up for delivery</span>
            </div>

            <div className="step-container">
                <div className="step-indicator" style={{ backgroundColor: deliveredColor }} />
                <span className="step-text">Delivered!</span>
            </div>
        </div>
    );
}
