import React from 'react';
import { illustrations, COLORS } from './illustrations';
import './Image.css'; // Import the CSS file for styling

const Image = ({ orderStatus }) => {
    let imageSrc;

    switch (orderStatus) {
        case 'PENDING':
            imageSrc = illustrations.received;
            break;
        case 'DELIVERED':
            imageSrc = illustrations.deliverd;
            break;
        case 'PACKING':
            imageSrc = illustrations.packing;
            break;
        case 'CANCELLED':
            imageSrc = COLORS.red;
            break;
        default:
            imageSrc = illustrations.deliverd;
            break;
    }

    return (
        <div className="order-success">
            <img
                src={imageSrc}
                alt={orderStatus}
                className="location-image"
            />
        </div>
    );
};

export default Image;
