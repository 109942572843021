import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

const Logout = () => {
    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            secureLocalStorage.removeItem("accessToken");
            secureLocalStorage.removeItem("id");
            secureLocalStorage.removeItem("user_type");
            // navigate('/');
            window.location.href = '/';
        } catch (error) {
            console.error('Error clearing AsyncStorage:', error);
        }
    };
    useEffect(() => {
        handleLogout();
    }, [])

};

export default Logout