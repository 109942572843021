import React from 'react'

export default function Coupons() {
return (
<>
    {/*
    <!-- Navbar --> */}
    <div className="osahan-header-main py-3 px-4">
        <div className="d-flex align-items-center">
            <div className="gap-3 d-flex align-items-center">
                <a href="/"><i className="bi bi-arrow-left d-flex text-success h3 m-0 back-page"></i></a>
                <h3 className="fw-bold m-0">Coupons</h3>
            </div>
            <div className="ms-auto gap-2 d-flex align-items-center">
                <a href="notifications.html" className="link-dark"><i
                        className="bi bi-bell-fill d-flex m-0 h3 header-bell"></i></a>
                <a className="toggle osahan-toggle text-dark m-0" href="#"><i
                        className="bi bi-list d-flex m-0 h3 header-list"></i></a>
            </div>
        </div>
    </div>
    {/*
    <!-- body --> */}
    <div className="mx-4 mb-4 bg-white rounded-4 shadow">
        <div className="p-4 border-bottom">
            <form>
                <input className="shadow-none form-control border-0 p-0" type="text" placeholder="Enter Coupon Code"
                    aria-label="default input example" />
            </form>
        </div>
        <div className="bg-light px-4 py-3">
            <h5 className="m-0">Available Coupons</h5>
        </div>
        <div className="p-4">
            <div className="d-flex gap-3">
                <div className="mb-3"><img src="img/p1.png" alt=""
                        className="img-fluid cw-50 ch-50 border rounded-pill object-fit-contain p-1" /></div>
                <div className="w-100">
                    <div className="mb-2">
                        <div className="h4 m-0">Get 50% OFF up to $100</div>
                    </div>
                    <p className="text-muted">Valid on total value of items worth $159 or more.&nbsp;<span
                            className="text-dark">View Details</span></p>
                    <div className="d-flex justify-content-between pb-3 align-items-center">
                        <div className="text-uppercase fw-bold border border-success text-success rounded px-2 py-1 h5 m-0">
                            maxsafety</div>
                        <div><a href="offers.html"
                                className="text-decoration-none text-success fw-bold text-uppercase">Apply</a></div>
                    </div>
                    <div className="text-danger">You will save $100 with this code</div>
                    <hr />
                </div>
            </div>
            <div className="d-flex gap-3">
                <div className="mb-3"><img src="img/p1.png" alt=""
                        className="img-fluid cw-50 ch-50 border rounded-pill object-fit-contain p-1" /></div>
                <div className="w-100">
                    <div className="mb-2">
                        <div className="h4 m-0">Get 40% OFF up to $200</div>
                    </div>
                    <p className="text-muted">Valid on total value of items worth $159 or more.&nbsp;<span
                            className="text-dark">View Details</span></p>
                    <div className="d-flex justify-content-between pb-3 align-items-center">
                        <div className="text-uppercase fw-bold border border-success text-success rounded px-2 py-1 h5 m-0">
                            zompaytm</div>
                        <div><a href="offers.html"
                                className="text-decoration-none text-success fw-bold text-uppercase">Apply</a></div>
                    </div>
                    <div className="text-danger">You will save $100 & get extra cashback with this code</div>
                    <hr />
                </div>
            </div>
            <div className="d-flex gap-3">
                <div className="mb-3"><img src="img/p2.png" alt=""
                        className="img-fluid cw-50 ch-50 border rounded-pill object-fit-contain p-1" /></div>
                <div className="w-100">
                    <div className="mb-2">
                        <div className="h4 m-0">Get 60% OFF up to $140</div>
                    </div>
                    <p className="text-muted">Valid on total value of items worth $159 or more.&nbsp;<span
                            className="text-dark">View Details</span></p>
                    <div className="d-flex justify-content-between pb-3 align-items-center">
                        <div className="text-uppercase fw-bold border border-success text-success rounded px-2 py-1 h5 m-0">
                            simplparty</div>
                        <div><a href="offers.html"
                                className="text-decoration-none text-success fw-bold text-uppercase">Apply</a></div>
                    </div>
                    <div className="text-danger">You will save $140 with this code</div>
                </div>
            </div>
        </div>
    </div>
</>
)
}