import React from 'react'
import { Link } from 'react-router-dom'
import Footer from '../Includes/Footer'
import { contactSchema } from '../../schema';
import GeneralService from '../../services/general.service';
import { useFormik } from 'formik';
import secureLocalStorage from 'react-secure-storage';
import { toast } from 'react-toastify';

export default function ContactUs() {
   const { values, errors, touched, handleBlur, handleChange, handleSubmit, resetForm } = useFormik({
      enableReinitialize: true,
      initialValues: {
         message: "",
      },
      validationSchema: contactSchema,
      onSubmit: async (values, actions) => {
         try {
            const userId = secureLocalStorage.getItem("id");
            const response = await GeneralService.submitMessage(userId, values.message);
            toast.success("Your message has been sent");
            // console.log('Sign up successful', response.data);
            // toast.success("Sign up successful!"); // Display success message
            resetForm(); // Reset the form fields
         } catch (error) {
            console.error('Sign up failed', error.response ? error.response.data : error.message);
            if (error?.response?.status === 422) {
               toast.error("Message is required");
            } else {
               toast.error("Server error. Please try again later.");
            }
            // toast.error('Sign up failed. Please try again.'); // Show error message
         } finally {
            actions.setSubmitting(false);
         }
      },
   });


   return (
      <>
         <div className="osahan-header-main py-3 px-4">
            <div className="d-flex align-items-center">
               <div className="gap-3 d-flex align-items-center">
                  <Link to="/">
                     <i className="bi bi-arrow-left d-flex text-success h3 m-0 back-page"></i>
                  </Link>
                  <h3 className="fw-bold m-0">Contact Us</h3>
               </div>
            </div>
         </div>
         <div className="mx-4 mb-4 bg-white rounded-4 shadow overflow-hidden p-4">
            <p>For more information, contact us on WhatsApp at <i class="bi bi-whatsapp"></i> 0346-5477825</p>
            <form noValidate onSubmit={handleSubmit}>
               <div className="mb-3">
                  <label className="form-label text-muted">Message</label>
                  <input
                     id="message"
                     name="message"
                     onChange={handleChange}
                     type="text"
                     className="shadow-none form-control"
                     value={values.message} />
                  {touched.message && errors.message && <p style={{ color: "red" }}>{errors.message}</p>}
               </div>

               <div className="d-grid">
                  <button type="submit" className="btn btn-success btn-lg rounded-pill">
                     Send Message
                  </button>
               </div>
            </form>
         </div>
         <Footer />
      </>
   )
}
