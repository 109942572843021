import React from "react";

export default function EnableLocation() {
  return (
    <>
      <div className="osahan-header-main py-3 px-4">
        <div className="d-flex align-items-center">
          <div className="gap-3 d-flex align-items-center">
            <a href="/">
              <i className="bi bi-arrow-left d-flex text-success h3 m-0 back-page"></i>
            </a>
            <h3 className="fw-bold m-0">My Addresses</h3>
          </div>
          <div className="ms-auto gap-2 d-flex align-items-center">
            <a href="notifications.html" className="link-dark">
              <i className="bi bi-bell-fill d-flex m-0 h3 header-bell"></i>
            </a>
            <a className="toggle osahan-toggle text-dark m-0" href="#">
              <i className="bi bi-list d-flex m-0 h3 header-list"></i>
            </a>
          </div>
        </div>
      </div>
      <div className="p-3 text-center">
        <i className="fa-solid fa-location-crosshairs text-success fs-lg pt-5 pb-3"></i>
        <div className="text-center">
          <div className="display-4 fw-bold mt-4 mb-2">
            We don't have your
            <br /> location, yet!
          </div>
          <div className="fs-5 text-muted">
            Set your location to start exploring
            <br /> Stores near you
          </div>
        </div>
        <div className="pt-4 pb-5"></div>
        <div className="d-grid fixed-bottom gap-3 p-4">
          <a href="/locations" className="btn btn-success btn-lg rounded-pill">
            <i className="fa-solid fa-location-crosshairs"></i>&nbsp;Enable Device
            Location
          </a>
          <a
            href="/locations"
            className="btn btn-outline-success btn-lg rounded-pill"
          >
            Enter Your Location Manually
          </a>
        </div>
      </div>
    </>
  );
}
