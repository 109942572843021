import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../Includes/Footer";
import GeneralService from "../../services/general.service";
import secureLocalStorage from "react-secure-storage";
import './Store.css'

export default function Store() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false)
  const [ongoingData, setOngoingData] = useState([]);
  const [historyData, setHistoryData] = useState([]);
  var userId = 0;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    userId = secureLocalStorage.getItem("id");
    getOrderOngoing(userId);
    getOrderHistory(userId);
  }

  const getOrderOngoing = async (id) => {
    try {
      setIsLoading(true);
      const ordersData = await GeneralService.listOrdersByUserIdOngoing(id);
      const { data } = ordersData;
      const { response } = data;
      // console.log(`ongoing-data=${JSON.stringify(response)}`);
      setIsLoading(false);
      setOngoingData(response);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      setOngoingData([]);
    }
  }

  const getOrderHistory = async (id) => {
    try {
      setIsLoading(true);
      const ordersData = await GeneralService.listOrdersByUserIdHistory(id);
      const { data } = ordersData;
      const { response } = data;
      console.log(`data=${JSON.stringify(response)}`);
      setIsLoading(false);
      setHistoryData(response);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      setHistoryData([]);
    }
  }


  return (
    <>
        <div className="osahan-header-main py-3 px-4">
  <div className="container-fluid">
    <div className="row align-items-center">
      <div className="col">
        <div className="d-flex align-items-center">
          <Link to="/" className="text-decoration-none">
            <i className="bi bi-arrow-left d-flex text-success h3 m-0 back-page"></i>
          </Link>
          <h3 className="fw-bold m-0 ms-3">My Orders</h3>
        </div>
      </div>
      <div className="col-auto ms-auto">
        <Link to="/logout" className="text-white text-decoration-none">
          <i className="bi bi-box-arrow-right d-flex m-0 h3 header-bell"></i>
        </Link>
      </div>
    </div>
  </div>
</div>
      <main className="sticky-top">
        <div className="bg-light">
          <div className="row m-0">
            <div className="col-12 p-0">
              <ul className="nav osahan-tab row m-0" id="pills-tab" role="tablist">
                <li className="nav-item col p-0" role="presentation">
                  <button
                    className="nav-link bg-light active w-100"
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    Ongoing Orders
                  </button>
                </li>
                <li className="nav-item col p-0" role="presentation">
                  <button
                    className="nav-link bg-light w-100"
                    id="pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                  >
                    History
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </main>
      <div className="p-4">
        <div className="row">
          <div className="col-12">
            <div className="tab-content" id="pills-tabContent">
              {/* Ongoing Orders Tab */}
              <div
                className="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <div>
                  {ongoingData.map((item) => (
                    <div className="store-list-2 mb-3" key={item.id}>
                      <div className="row justify-content-between">
                        <div className="col-9">
                          <h3 className="fw-bold mb-1">Order # {item.order_no}</h3>
                          <h6 className="fw-bold mb-1">Rs. {item.bill} | {item.created_at}</h6>
                          <button
                            onClick={() =>
                              navigate('/your-order', {
                                state: {
                                  orderId: item.id,
                                  orderStatus: item.status,
                                  orderNo: item.order_no,
                                  orderDate: item.created_at,
                                },
                              })
                            }
                            className="btn btn-success btn-new d-grid rounded-pill"
                          >
                            Track Order
                          </button>
                        </div>
                        <div className="col-3 d-flex align-items-center">
                          <div className={`status-indicator ${item.status.toLowerCase()}`}>
                            <h3 className="fw-bold mb-1">{item.status}</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="p-5"></div>
              </div>
              {/* History Tab */}
              <div
                className="tab-pane fade"
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
              >
                <div>
                  {historyData.map((item) => (
                    <div className="store-list-2 mb-3" key={item.id}>
                      <div className="row justify-content-between">
                        <div className="col-9">
                          <h3 className="fw-bold mb-1">Order # {item.order_no}</h3>
                          <h6 className="fw-bold mb-1">Rs. {item.bill} | {item.created_at}</h6>
                          <button
                            onClick={() =>
                              navigate('/order-details', {
                                state: { orderId: item.id },
                              })
                            }
                            className="btn btn-success btn-new d-grid rounded-pill"
                          >
                            Details
                          </button>
                        </div>
                        <div className="col-3 d-flex align-items-center">
                          <div className={`status-indicator ${item.status.toLowerCase()}`}>
                            <h3 className="fw-bold mb-1">{item.status}</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="p-5"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
