import React from "react";
import { Link } from "react-router-dom";

export default function Cart() {
  return (
    <>
      <div className="osahan-header-main py-3 px-4">
        <div className="d-flex align-items-center">
          <div className="gap-3 d-flex align-items-center">
            <Link to="/">
              <i className="bi bi-arrow-left d-flex text-success h3 m-0 back-page"></i>
            </Link>
            <h3 className="fw-bold m-0">Cart</h3>
          </div>
        </div>
      </div>
      <div className="mx-4 mb-4 bg-white rounded-4 shadow overflow-hidden">
        {/* <!-- body --> */}
        <div className="bg-white p-4 border-bottom">
          <div>
            {/* <!-- 1st --> */}
            <div className="mb-3 d-flex gap-3">
              <img src="img/veg.jpeg" className="img-fluid mb-auto ch-20" alt="#" />
              <div>
                <h6 className="fw-bold mb-1">Fresh Harvest Apples</h6>
                <p className="mb-0">$150</p>
                <p className="small text-muted m-0">1 Kgs</p>
              </div>
              <div className="ms-auto text-end cw-80">
                <div className="btn btn-white btn-sm border border-success px-2 rounded-pill overflow-hidden">
                  <div className="d-flex align-items-center justify-content-between gap-2">
                    <div className="minus">
                      <i className="fa-solid fa-minus text-success"></i>
                    </div>
                    <input
                      className="shadow-none form-control text-center border-0 p-0 box"
                      type="text"
                      placeholder=""
                      aria-label="default input example"
                      value="1"
                    />
                    <div className="plus">
                      <i className="fa-solid fa-plus text-success"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- 2nd --> */}
            {/* <div className="mb-3 d-flex gap-3">
              <img src="img/veg.jpeg" className="img-fluid mb-auto ch-20" alt="#" />
              <div>
                <h6 className="fw-bold mb-1">Sweet Treat Pastries</h6>
                <p className="mb-0">$90</p>
                <p className="small text-muted m-0">1 Ltr</p>
              </div>
              <div className="ms-auto text-end cw-80">
                <div className="btn btn-white btn-sm border border-success px-2 rounded-pill overflow-hidden">
                  <div className="d-flex align-items-center justify-content-between gap-2">
                    <div className="minus">
                      <i className="fa-solid fa-minus text-success"></i>
                    </div>
                    <input
                      className="shadow-none form-control text-center border-0 p-0 box"
                      type="text"
                      placeholder=""
                      aria-label="default input example"
                      value="1"
                    />
                    <div className="plus">
                      <i className="fa-solid fa-plus text-success"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <!-- Add more items --> */}
            {/* <div>
              <a href="/search-list" className="text-decoration-none text-danger">
                <i className="fa-solid fa-plus"></i>&nbsp;Add more items
              </a>
            </div> */}
          </div>
          {/* <hr /> */}
          {/* <div className="text-muted small">
            <i className="fa-solid fa-layer-group me-2"></i>&nbsp;Do you want to add
            cooking instructions?
          </div> */}
        </div>
        {/* <!-- Coupons --> */}
        {/* <div className="bg-light p-4 border-bottom">
          <a href="coupons.html">
            <div className="d-flex justify-content-between">
              <div className="d-flex gap-2 h5 m-0">
                <div>
                  <i className="fa-solid fa-tags text-danger"></i>
                </div>
                <div className="fw-bold text-muted">Use coupons</div>
              </div>
              <div>
                <i className="fa-solid fa-angle-right text-danger"></i>
              </div>
            </div>
          </a>
        </div> */}
        {/* <!-- Total price --> */}
        {/* <div className="bg-white p-4 border-bottom">
          <div className="d-flex justify-content-between">
            <div>Item Total</div>
            <div>$218.00</div>
          </div>
          <div className="accordion">
            <div className="accordion-item bg-white border-0">
              <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                <button
                  className="accordion-button px-0 pt-3 pb-3 bg-white border-0 shadow-none h5 mb-0 fw-normal text-dark"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseTwo"
                  aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseTwo"
                >
                  Taxes & charges
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseTwo"
                className="accordion-collapse collapse show"
                aria-labelledby="panelsStayOpen-headingTwo"
              >
                <div className="accordion-body px-0 pt-0">
                  <div className="d-flex justify-content-between text-muted mb-2">
                    <div>Delivery Charges for 3 Km</div>
                    <div>
                      <span className="text-danger">
                        <del>$19</del>
                      </span>{" "}
                      FREE
                    </div>
                  </div>
                  <div className="d-flex justify-content-between text-muted">
                    <div>Taxes</div>
                    <div>$12.50</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between h4 fw-bold m-0">
            <div>Grand Total</div>
            <div>$229.50</div>
          </div>
        </div> */}
        {/* <!-- Tip  --> */}
        {/* <div className="bg-white p-4 border-bottom">
          <div className="mb-2">
            <h5 className="fw-bold mb-2">Please tip your delivery partner</h5>
            <p className="text-muted text-muted">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod,
              aspernatur modi quasi laboriosam veritatis perferendis.
            </p>
          </div>
          <div className="d-flex gap-2">
            <input
              type="radio"
              className="btn-check"
              name="btnradio"
              id="btnradio1"
              checked
            />
            <label
              className="btn btn-outline-success btn-sm rounded-pill"
              for="btnradio1"
            >
              <i className="bi bi-wallet me-1"></i>&nbsp;$20
            </label>
            <input
              type="radio"
              className="btn-check"
              name="btnradio"
              id="btnradio2"
            />
            <label
              className="btn btn-outline-success btn-sm rounded-pill"
              for="btnradio2"
            >
              <i className="bi bi-wallet me-1"></i>&nbsp;$30
            </label>
            <input
              type="radio"
              className="btn-check"
              name="btnradio"
              id="btnradio4"
            />
            <label
              className="btn btn-outline-success btn-sm rounded-pill"
              for="btnradio4"
            >
              <i className="bi bi-wallet me-1"></i>&nbsp;Other
            </label>
          </div>
        </div> */}
        {/* <!-- Cancel policy --> */}
        {/* <div className="bg-white p-4">
          <h5 className="fw-bold">Cancellation Policy</h5>
          <p className="text-muted text-muted">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. In aliquam
            labore temporibus, quo ea accusantium repellendus pariatur.?
          </p>
          <div className="small">Avoid cacellation as it leads to food wastage</div>
        </div> */}
      </div>
      <div className="pt-5 pb-4"></div>
      {/* <!-- Fixed bottom --> */}
      <div className="fixed-bottom p-4">
        <a
          href="/enable-location"
          className="btn btn-success btn-lg d-grid rounded-pill"
        >
          <div className="d-flex justify-content-between">
            <div></div>
            <div>Add personal details</div>
            <div>
              <i className="fa-solid fa-caret-right"></i>
            </div>
          </div>
        </a>
      </div>
    </>
  );
}
