import React, { useState, useEffect } from 'react';

export default function Spinner() {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
       const timer = setTimeout(() => {
          setLoading(false);
       }, 2000); 
 
       return () => clearTimeout(timer);
    }, []);
 
    if (loading) {
       return (
          <div className="spinner-container">
             <div className="spinner-border text-success" role="status">
                <span className="visually-hidden">Loading...</span>
             </div>
          </div>
       );
    }
}
