import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import veggieLogo from '../Home/home-logo.png';
import './UpdatedHeader.css';
import secureLocalStorage from 'react-secure-storage';

export default function UpdatedHeader() {
  const [menuActive, setMenuActive] = useState(false);

  function toggleMenu() {
    setMenuActive(!menuActive);
  }

  let userId = secureLocalStorage.getItem("id");

  return (
    <>
      <header className="header updated">
        <div className="logo">
          <Link to="/" className="text-white">
            <img src={veggieLogo} alt="Logo" className="logo-home" />
          </Link>
        </div>
        <nav className="nav-links">
          <Link to="/">Home</Link>
          {userId && (
            <>
              <Link to="/contact-us">Contact</Link>
              <Link to="/personal-info">Profile</Link>
              <Link to="/cart">Cart</Link>
              <Link to="/logout">Logout</Link>
            </>
          )}
          {!userId && (
            <Link to="/sign-in">Login</Link>
          )}
        </nav>
        <div className="menu-icon" onClick={toggleMenu}>&#9776;</div>
      </header>
      <div className={`dropdown-menu ${menuActive ? 'active' : ''}`}>
        <Link to="/">Home</Link>
        {userId && (
          <>
            <Link to="/contact-us">Contact</Link>
            <Link to="/personal-info">Profile</Link>
            <Link to="/cart">Cart</Link>
            <Link to="/logout">Logout</Link>
          </>
        )}
        {!userId && (
          <Link to="/sign-in">Login</Link>
        )}
      </div>
    </>
  );
}
