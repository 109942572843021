import axios from "axios";

// const API_URL = "http://localhost/veggieking-api";
const API_URL = "https://api.veggieking.pk/public";

const login = (values) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return axios.post(
    `${API_URL}/login-user`,
    {
      phone: values.phone,
      password: values.password,
    },
    {
      headers: headers,
    }
  );
};
const register = (values) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.post(
    `${API_URL}/user-register`,
    {
      email: values.email,
      phone: values.phone,
      address: values.address,
      password: values.password,
      name: values.name,
    },
    {
      headers: headers,
    }
  );
};

const updateProfile = (values, id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.post(
    `${API_URL}/update-profile/${id}`,
    {
      name: values.name,
      address: values.address,
      email: values.email,
    },
    {
      headers: headers,
    }
  );
};

const listAllProducts = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    `${API_URL}/list-product`,
    {
      headers: headers,
    }
  );
};


const listSummary = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    `${API_URL}/list-summary`,
    {
      headers: headers,
    }
  );
};

const listMessages = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    `${API_URL}/list-messages`,
    {
      headers: headers,
    }
  );
};



const listAllCategories = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    `${API_URL}/list-categories`,
    {
      headers: headers,
    }
  );
};

const getCategoryById = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    `${API_URL}/category/${id}`,
    {
      headers: headers,
    }
  );
};

const getCharges = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    `${API_URL}/get-charges`,
    {
      headers: headers,
    }
  );
};

const getChargesByPrice = (price) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    `${API_URL}/get-charges-by-price/${price}`,
    {
      headers: headers,
    }
  );
};

const getMinCharges = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    `${API_URL}/get-min-charges`,
    {
      headers: headers,
    }
  );
};

const getNotification = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    `${API_URL}/notification`,
    {
      headers: headers,
    }
  );
};

const updateNotification = (values, adminId) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return axios.put(
    `${API_URL}/notification`, {
    heading: values.heading,
    body: values.body,
    body2: values.body2,
    button: values.button,
    notification_status: values.notificationStatus,
    updated_by: adminId,
  },
    {
      headers: headers,
    }
  );
};

const updateCharges = (price, id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.put(
    `${API_URL}/update-charges`, {
    price: price, updated_by: id
  },
    {
      headers: headers,
    }
  );
};

const getProductById = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    `${API_URL}/product/${id}`,
    {
      headers: headers,
    }
  );
};

const addRider = (values) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.post(
    `${API_URL}/add-rider`, {
    username: values.username,
    email: values.email,
    password: values.password,
    phone: values.phone,
    first_name: values.first_name,
    last_name: values.last_name,
  },
    {
      headers: headers,
    }
  );
};


const addSlider = (values) => {
  const formData = new FormData();
  formData.append('image', values.image);
  formData.append('created_by', 1);

  const headers = {
    "Content-Type": "multipart/form-data",
  };

  return axios.post(
    `${API_URL}/add-slider`,
    formData,
    {
      headers: headers,
    }
  )
    .then(response => {
      console.log('Add slider response:', response.data);
      return response.data;
    })
    .catch(error => {
      console.error('Error adding slider:', error);
      throw error;
    });
};

const addProduct = (values) => {
  const formData = new FormData();
  formData.append('name', values.name);
  formData.append('image', values.image);
  formData.append('price', values.sale_price);
  formData.append('purchase_price', values.purchase_price);
  formData.append('category', values.category);
  formData.append('created_by', 1);

  const headers = {
    "Content-Type": "multipart/form-data",
  };

  return axios.post(
    `${API_URL}/add-product`,
    formData,
    {
      headers: headers,
    }
  )
    .then(response => {
      console.log('Add product response:', response.data);
      return response.data;
    })
    .catch(error => {
      console.error('Error adding product:', error);
      throw error; // Rethrow the error to handle it in the calling component
    });
};

const updateProduct = (values, id) => {
  const formData = new FormData();
  formData.append('name', values.name);
  formData.append('image', values.image);
  formData.append('price', parseInt(values.sale_price, 10));
  formData.append('purchase_price', parseInt(values.purchase_price, 10));
  // formData.append('type', values.type);
  formData.append('updated_by', 1);

  const headers = {
    "Content-Type": "multipart/form-data",
  };

  return axios.post(
    `${API_URL}/update-product/${id}`,
    formData,
    {
      headers: headers,
    }
  )
    .then(response => {
      console.log('Add product response:', response.data);
      return response.data;
    })
    .catch(error => {
      console.error('Error adding product:', error);
      throw error; // Rethrow the error to handle it in the calling component
    });
};

const addCategory = (values) => {
  const formData = new FormData();
  formData.append('name', values.name);
  formData.append('image', values.image);
  formData.append('created_by', 1);

  const headers = {
    "Content-Type": "multipart/form-data",
  };

  return axios.post(
    `${API_URL}/add-category`,
    formData,
    {
      headers: headers,
    }
  )
    .then(response => {
      console.log('Add category response:', response.data);
      return response.data;
    })
    .catch(error => {
      console.error('Error adding category:', error);
      throw error; // Rethrow the error to handle it in the calling component
    });
};

const updateCategory = (values, id) => {
  const formData = new FormData();
  formData.append('name', values.name);
  formData.append('image', values.image);
  formData.append('created_by', 1);

  const headers = {
    "Content-Type": "multipart/form-data",
  };

  return axios.post(
    `${API_URL}/update-category/${id}`,
    formData,
    {
      headers: headers,
    }
  )
    .then(response => {
      console.log('Add category response:', response.data);
      return response.data;
    })
    .catch(error => {
      console.error('Error adding category:', error);
      throw error; // Rethrow the error to handle it in the calling component
    });
};

// const updateProduct = (values) => {
//     const headers = {
//         "Content-Type": "application/json",
//     };
//     return axios.post(
//         `${API_URL}/update-product/${values.id}`, {
//         name: values.name,
//         image: values.image,
//         price: values.price,
//         type: values.type,
//         updated_by: values.user_id,
//     },
//         {
//             headers: headers,
//         }
//     );
// };

const deleteProduct = (prodId, adminId) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.delete(
    `${API_URL}/delete-product/${prodId}/${adminId}`,
    {
      headers: headers,
    }
  );
};

const deleteSlider = (id, adminId) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.delete(
    `${API_URL}/delete-slider/${id}/${adminId}`,
    {
      headers: headers,
    }
  );
};

const deleteOrdDtlPrd = (id, adminId) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.delete(
    `${API_URL}/delete-ord-dtl-prd/${id}/${adminId}`,
    {
      headers: headers,
    }
  );
};

const deleteCategory = (catId, adminId) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.delete(
    `${API_URL}/delete-category/${catId}/${adminId}`,
    {
      headers: headers,
    }
  );
};

const deleteUserById = (userId, adminId) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.delete(
    `${API_URL}/delete-user/${userId}/${adminId}`,
    {
      headers: headers,
    }
  );
};


const listAllOrders = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    `${API_URL}/list-orders`,
    {
      headers: headers,
    }
  );
};

const productsRequired = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    `${API_URL}/products-required`,
    {
      headers: headers,
    }
  );
};

const listRecentOrders = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    `${API_URL}/list-recent-orders`,
    {
      headers: headers,
    }
  );
};

const dashboardCounters = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    `${API_URL}/admin-dashboard-counter`,
    {
      headers: headers,
    }
  );
};

const listOrdersByUserId = (values) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    `${API_URL}/list-orders-user/${values.user_id}`,
    {
      headers: headers,
    }
  );
};

const updatePassword = (token, email, password) => {
  console.log(token, email, password);
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.put(
    `${API_URL}/update-password`, {
    token: token, email: email, password: password
  },
    {
      headers: headers,
    }
  );
};

const updatePasswordById = (current_password, new_password, userId) => {
  const headers = {
    "Content-Type": "application/json",
  };
  // console.log(current_password, new_password, userId);
  return axios.put(
    `${API_URL}/edit-password`, {
    id: userId, current_password: current_password, new_password: new_password
  },
    {
      headers: headers,
    }
  );
};

const listOrdersDetailByOrderId = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    `${API_URL}/list-order-dtl/${id}`,
    {
      headers: headers,
    }
  );
};

const orderDtlInfoById = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    `${API_URL}/order-dtl-info/${id}`,
    {
      headers: headers,
    }
  );
};

const updateOrderFields = (custom_field, custom_note, id, updated_by) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.put(
    `${API_URL}/order-field/${id}`, {
    custom_field: custom_field,
    custom_note: custom_note,
    updated_by: updated_by
  },
    {
      headers: headers,
    }
  );
};

const updateOrderStatus = (id, status) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.put(
    `${API_URL}/order-status/${id}/${status}`,
    {
      headers: headers,
    }
  );
};

const updateProductStatus = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.put(
    `${API_URL}/product-status/${id}`, {
    'updated_by': 1,
  },
    {
      headers: headers,
    }
  );
};

const productHideUnhide = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.put(
    `${API_URL}/product-hide-unhide/${id}`, {
    'updated_by': 1,
  },
    {
      headers: headers,
    }
  );
};

const userActiveDisabled = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.put(
    `${API_URL}/user-active-disabled/${id}`, {
    'updated_by': 1,
  },
    {
      headers: headers,
    }
  );
};

const categoryHideUnhide = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.put(
    `${API_URL}/category-hide-unhide/${id}`, {
    'updated_by': 1,
  },
    {
      headers: headers,
    }
  );
};

const orderInfoById = (values) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    `${API_URL}/order-info/${values.id}`,
    {
      headers: headers,
    }
  );
};

const getUserById = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    `${API_URL}/user/${id}`,
    {
      headers: headers,
    }
  );
};

const submitMessage = (userId, message) => {
  const headers = {
    "Content-Type": "application/json",
  };
  console.log(userId, message);
  return axios.post(
    `${API_URL}/submit-message`,
    {
      created_by: userId,
      message: message,
    },
    {
      headers: headers,
    }
  );
};

const updateUserById = (values, id) => {
  console.log(values)
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.put(
    `${API_URL}/user/${id}`, {
    name: values.name,
    address: values.address,
    email: values.email,
    phone: values.phone,
    updated_by: id,
  },
    {
      headers: headers,
    }
  );
};

const updateUserPassById = (values, id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.put(
    `${API_URL}/update-user-pass/${id}`, {
    password: values.password,
    updated_by: id,
  },
    {
      headers: headers,
    }
  );
};

const updateRiderById = (values, id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.put(
    `${API_URL}/update-rider/${id}`, {
    first_name: values.first_name,
    last_name: values.last_name,
    phone: values.phone,
    updated_by: id,
  },
    {
      headers: headers,
    }
  );
};

const orderInfoForPrintById = (values) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    `${API_URL}/order-info-print/${values.id}`,
    {
      headers: headers,
    }
  );
};

const updateOrderInfoById = (values) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.put(
    `${API_URL}/order-status/${values.id}`, {
    custom_field: values.custom_field,
    custom_note: values.custom_note,
    created_at: values.created_at,
  },
    {
      headers: headers,
    }
  );
};

const listAllRiders = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    `${API_URL}/list-user/rider`,
    {
      headers: headers,
    }
  );
};

const listAllUsers = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    `${API_URL}/list-user/user`,
    {
      headers: headers,
    }
  );
};

const listAllSliders = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    `${API_URL}/list-sliders`,
    {
      headers: headers,
    }
  );
};



const assignOrderToRider = (riderId, orderId, adminId) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.put(
    `${API_URL}/order-rider`, {
    rider_id: riderId,
    order_id: orderId,
    admin_id: adminId,
  },
    {
      headers: headers,
    }
  );
};

const listAssignedOrderByRiderId = (values) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    `${API_URL}/list-rider-order/${values.id}`,
    {
      headers: headers,
    }
  );
};

const sendNotification = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    `${API_URL}/send-notification`,
    {
      headers: headers,
    }
  );
};

const listLimProductByCatCart = (id, userId) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    `${API_URL}/list-lim-product-by-cat-cart/${id}/${userId}`,
    {
      headers: headers,
    }
  );
};

const getSliders = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    `${API_URL}/list-sliders`,
    {
      headers: headers,
    }
  );
};

const cartCounterByUserId = (id) => {
  // console.log(`id=${id}`);
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    `${API_URL}/cart-counter/${id}`,
    {
      headers: headers,
    }
  );
};

const listCartByUserId = (id) => {
  // console.log(`id=${id}`);
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    `${API_URL}/list-cart/${id}`,
    {
      headers: headers,
    }
  );
};

const increaseQty = (userId, prodId) => {
  console.log(userId, prodId);
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.put(
    `${API_URL}/increase-quantity`, {
    created_by: userId,
    prod_id: prodId,
  },
    {
      headers: headers,
    }
  );
};


const listProductByCatCart = (id, userId) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    `${API_URL}/list-product-by-cat-cart/${id}/${userId}`,
    {
      headers: headers,
    }
  );
};

const decreaseQty = (userId, prodId) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.put(
    `${API_URL}/decrease-quantity`, {
    created_by: userId,
    prod_id: prodId,
  },
    {
      headers: headers,
    }
  );
};

const deleteCart = (userId, prodId) => {
  const headers = {
    "Content-Type": "application/json",
  };

  const data = {
    created_by: userId,
    prod_id: prodId,
  };

  return axios.delete(
    `${API_URL}/delete-cart`,
    {
      headers: headers,
      data: data
    }
  );
};

const addCart = (userId, prodId) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.post(
    `${API_URL}/add-cart`, {
    created_by: userId,
    prod_id: prodId,
  },
    {
      headers: headers,
    }
  );
};

const listOrdersByUserIdOngoing = (userId) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    `${API_URL}/list-orders-user-ongoing/${userId}`,
    {
      headers: headers,
    }
  );
};

const listOrdersByUserIdHistory = (userId) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    `${API_URL}/list-orders-user-history/${userId}`,
    {
      headers: headers,
    }
  );
};

const placeOrder = (cart, userId, address, instruction) => {
  console.log(cart, userId, address, instruction);
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.post(
    `${API_URL}/place-order-new`, {
    cart: cart,
    user_id: userId,
    address: address,
    instruction: instruction,
  },
    {
      headers: headers,
    }
  );
};

const listOngoingAssignedOrderByRiderId = (id, status) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    `${API_URL}/list-rider-order-ongoing/${id}`,
    {
      headers: headers,
    }
  );
};

const listHistoryAssignedOrderByRiderId = (id, status) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    `${API_URL}/list-rider-order-history/${id}`,
    {
      headers: headers,
    }
  );
};

const forgot = (values) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.post(
    `${API_URL}/forgot`,
    {
      email: values.email,
    },
    {
      headers: headers,
    }
  );
};

const getProductCartById = (id, userId) => {
  console.log(id, userId);
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    `${API_URL}/product-cart/${id}/${userId}`,
    {
      headers: headers,
    }
  );
};

const checkForgotToken = (token, email) => {
  // console.log(token, email);
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    `${API_URL}/check-forgot/${token}/${email}`,
    {
      headers: headers,
    }
  );
};

const forgotOtp = (email) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.post(
    `${API_URL}/forgot`,
    {
      email: email,
    },
    {
      headers: headers,
    }
  );
};

const GeneralService = {
  listAllProducts, forgot, forgotOtp, checkForgotToken, placeOrder, listOngoingAssignedOrderByRiderId, listHistoryAssignedOrderByRiderId, login, register, addCart,
  updateProfile, listProductByCatCart, listOrdersByUserIdOngoing, updatePasswordById, listOrdersByUserIdHistory,
  listCartByUserId, increaseQty, decreaseQty, deleteCart, getSliders, dashboardCounters, listLimProductByCatCart, cartCounterByUserId,
  getUserById, submitMessage, deleteUserById, updateUserById, updateRiderById, getProductById, updateCategory, getCategoryById,
  listAllCategories, addProduct, addCategory, updateProduct, deleteProduct, deleteCategory, listAllOrders, listRecentOrders,
  listOrdersByUserId, listOrdersDetailByOrderId, orderDtlInfoById, updateOrderStatus, orderInfoById, orderInfoForPrintById,
  updateOrderInfoById, listAllRiders, listAllUsers, assignOrderToRider, listAssignedOrderByRiderId, sendNotification, addRider,
  updateOrderFields, getCharges, getChargesByPrice, getMinCharges, updatePassword, updateCharges, productsRequired, deleteOrdDtlPrd, listSummary, listMessages, getProductCartById, getNotification
};
export default GeneralService;