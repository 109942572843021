import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../Includes/Footer";
import GeneralService from "../../services/general.service";
import secureLocalStorage from "react-secure-storage";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';


export default function RiderOrders() {
  const MySwal = withReactContent(Swal);

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false)
  const [ongoingData, setOngoingData] = useState([]);
  const [historyData, setHistoryData] = useState([]);
  var userId = 0;

  const userType = secureLocalStorage.getItem("user_type");
  useEffect(() => {
    if (userType == 'rider') {
      navigate("/rider-orders");
    }
  }, [userType]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    userId = secureLocalStorage.getItem("id");
    getOrderOngoing(userId);
    getOrderHistory(userId);
  }

  const getOrderOngoing = async (id) => {
    try {
      setIsLoading(true);
      const ordersData = await GeneralService.listOngoingAssignedOrderByRiderId(id);
      const { data } = ordersData;
      const { response } = data;
      // console.log(`ongoing-data=${JSON.stringify(response)}`);
      setIsLoading(false);
      setOngoingData(response);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      setOngoingData([]);
    }
  }

  const getOrderHistory = async (id) => {
    try {
      setIsLoading(true);
      const ordersData = await GeneralService.listHistoryAssignedOrderByRiderId(id);
      const { data } = ordersData;
      const { response } = data;
      console.log(`data=${JSON.stringify(response)}`);
      setIsLoading(false);
      setHistoryData(response);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      setHistoryData([]);
    }
  }

  // swal confirmation
  const confirmDeliver = (orderId, orderNo) => {
    MySwal.fire({
      title: `Are you sure you want to mark this order #${orderNo} as delivered?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        handleDeliveryConfirmation(orderId);
      }
    });
  };


  // swal confirmation
  const handleDeliveryConfirmation = async (orderId) => {
    console.log(orderId);
    try {
      const response = await GeneralService.updateOrderStatus(orderId, 'delivered');
      const { data } = response;
      const { response: res } = data;
      MySwal.fire({
        title: `Delivery Status: ${res}`,
        icon: 'warning',
        // showCancelButton: true,
        confirmButtonText: 'Ok',
        // cancelButtonText: 'No',
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          refreshPage();
        }
      });

      // console.log(res);
    } catch (err) {
      console.log(err?.response);
    }
  };

  const refreshPage = () => {
    fetchData();
  }

  return (
    <>
      <div className="osahan-header-main py-3 px-4" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div className="d-flex align-items-center">
          <div className="gap-3 d-flex align-items-center">
            <h3 className="fw-bold m-0">Orders</h3>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <Link to="/logout" className="text-white">
            <i className="bi bi-box-arrow-right d-flex m-0 h3 header-bell"></i>
          </Link>
        </div>
      </div>
      <main className="sticky-top">
        <div className="bg-light">
          <div className="row m-0">
            <div className="col-12 p-0">
              <ul className="nav osahan-tab row m-0" id="pills-tab" role="tablist">
                <li className="nav-item col p-0" role="presentation">
                  <button
                    className="nav-link bg-light active w-100"
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    Ongoing Orders
                  </button>
                </li>
                <li className="nav-item col p-0" role="presentation">
                  <button
                    className="nav-link bg-light w-100"
                    id="pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                  >
                    History
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </main>
      <div className="p-4">
        <div className="row">
          <div className="col-12">
            <div className="tab-content" id="pills-tabContent">
              {/* <!-- 1st tab --> */}
              <div
                className="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <div>
                  {/* <!-- 1st product --> */}
                  {
                    ongoingData.map(item => {
                      return (
                        <div className="store-list-2" style={{ marginBottom: "10px" }}>
                          <div className="row justify-content-between">
                            <div className="col-9">
                              <h3 className="fw-bold mb-1">Order # {item.order_no}</h3>
                              <h6 className="fw-bold mb-1">Rs. {item.bill} | {item.created_at}</h6>
                              <div style={{ display: 'flex', gap: '10px' }}>
                                <button
                                  onClick={() =>
                                    navigate("/rider-user-details", {
                                      state: {
                                        custName: item.name,
                                        custEmail: item.email,
                                        custPhone: item.phone,
                                        custAddress: item.address,
                                      },
                                    })
                                  }
                                  className="btn btn-success btn-new rounded-pill"
                                >
                                  User Details
                                </button>
                                <button
                                  onClick={() =>
                                    navigate("/rider-order-details", {
                                      state: {
                                        orderId: item.id,
                                        orderStatus: item.status,
                                        orderNo: item.order_no,
                                        orderDate: item.created_at,
                                      },
                                    })
                                  }
                                  className="btn btn-success btn-new rounded-pill"
                                >
                                  Order Details
                                </button>
                                <button
                                  onClick={() => confirmDeliver(item.id, item.order_no)}
                                  className="btn btn-success btn-new rounded-pill"
                                >
                                  Mark Deliver
                                </button>
                              </div>
                            </div>
                            <div className="col-3">
                              <div className="card-body d-grid px-0 pt-2 pb-0">
                                <h3 className="fw-bold mb-1" color={item.status === "PENDING" ? "yellow" :
                                  (item.status === "DELIVERED" ? "green" :
                                    (item.status === "PACKING" ? "blue" :
                                      (item.status === "CANCELLED" ? "red" : "blue")))}>{item.status}</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })
                  }

                </div>
                <div className="p-5"></div>
              </div>
              {/* <!-- 2nd tab --> */}
              <div
                className="tab-pane fade"
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
              >
                <div>
                  {/* <!-- 1st product --> */}
                  {
                    historyData.map(item => {
                      return (
                        <div className="store-list-2" style={{ marginBottom: "10px" }}  >
                          <div className="row justify-content-between">
                            <div className="col-9">
                              <h3 className="fw-bold mb-1">Order # {item.order_no}</h3>
                              <h6 className="fw-bold mb-1">Rs. {item.bill} | {item.created_at}</h6>
                              <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                                <button
                                  onClick={() =>
                                    navigate("/rider-user-details", {
                                      state: {
                                        custName: item.name,
                                        custEmail: item.email,
                                        custPhone: item.phone,
                                        custAddress: item.address,
                                      },
                                    })
                                  }
                                  className="btn btn-success btn-new rounded-pill"
                                >
                                  User Details
                                </button>
                                <button
                                  onClick={() =>
                                    navigate("/rider-order-details", {
                                      state: {
                                        orderId: item.id,
                                        orderStatus: item.status,
                                        orderNo: item.order_no,
                                        orderDate: item.created_at,
                                      },
                                    })
                                  }
                                  className="btn btn-success btn-new rounded-pill"
                                >
                                  Order Details
                                </button>
                              </div>
                            </div>

                            <div className="col-3">
                              <div className="card-body d-grid px-0 pt-2 pb-0">
                                <h3 className="fw-bold mb-1" color={item.status === "PENDING" ? "yellow" :
                                  (item.status === "DELIVERED" ? "green" :
                                    (item.status === "PACKING" ? "blue" :
                                      (item.status === "CANCELLED" ? "red" : "blue")))}>{item.status}</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
                {/* <div className="p-5"></div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
}
