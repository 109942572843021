import React from 'react'

export default function Locations() {
  return (
    <>
         <div className="bg-white osahan-header-main fixed-top shadow-sm py-3 px-4">
         <div className="d-flex align-items-center">
            <div className="gap-3 d-flex align-items-center">
               <a href="/"><i className="bi bi-arrow-left d-flex text-success h3 m-0 back-page"></i></a>
               <h3 className="fw-bold m-0">Confirm delivery Location</h3>
            </div>
            <div className="ms-auto gap-2 d-flex align-items-center">
               <a href="notifications.html" className="link-dark"><i className="bi bi-bell-fill d-flex m-0 h3 header-bell"></i></a>
               <a className="toggle osahan-toggle text-dark m-0" href="#"><i className="bi bi-list d-flex m-0 h3 header-list"></i></a>
            </div>
         </div>
      </div>
      {/* <!-- Navbar --> */}
      {/* <!-- body --> */}
      <div>
         <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d54820.77171390824!2d75.15290850394938!3d30.82230855048896!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391a088ea744ea81%3A0x3e99f7e54a9b5ae5!2sNature%20Park!5e0!3m2!1sen!2sin!4v1647947561512!5m2!1sen!2sin" className="w-100 vh-100 border-0" allowfullscreen="" loading="lazy"></iframe>
      </div>
      {/* <!-- Fixed bottom --> */}
      <div className="mx-4 mb-4 bg-white rounded-4 shadow fixed-bottom p-4">
         <div className="d-flex align-items-center justify-content-between mb-3">
            <div className="d-flex align-items-center gap-3">
               <div><i className="fa-solid fa-location-dot text-danger h4"></i></div>
               <div>
                  <h4 className="fw-bold mb-1">Akshya Nagar</h4>
                  <p className="text-muted m-0">Raurthy Nagar, Bangalore-56001</p>
               </div>
            </div>
            <div>
               <a href="/personal-info" className="text-decoration-none text-danger text-uppercase">change</a>
            </div>
         </div>
         <div className="d-grid">
            <a href="/personal-info" className="btn btn-success btn-lg rounded-pill">Confirm Location</a>
         </div>
      </div>
    </>
  )
}
