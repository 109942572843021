import React from 'react'

export default function Wallet() {
  return (
    <>
     <div className="p-3">
         <div className="d-flex align-items-center">
            <div className="gap-3 d-flex align-items-center">
               <a href="/"><i className="bi bi-arrow-left d-flex text-success h3 m-0 back-page"></i></a>
               <button type="button" className="btn bg-none border-0 p-0" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
               <span className="text-success h4 m-0"><i className="fa-solid fa-location-dot"></i>&nbsp;<span>Set Location</span>&nbsp;<i className="fa-solid fa-chevron-down"></i></span>
               </button>
            </div>
            <div className="ms-auto gap-2 d-flex align-items-center">
               <a href="notifications.html" className="link-dark"><i className="bi bi-bell-fill d-flex m-0 h3 header-bell"></i></a>
               <a data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" className="osahan-toggle text-dark m-0" href="#"><i className="bi bi-list d-flex m-0 h3 header-list"></i></a>
            </div>
         </div>
      </div>
      {/* <!-- Navbar --> */}
      <nav className="navbar navbar-light">
         <div className="container">
            <div className="offcanvas offcanvas-start" tabindex="-1" id="offcanvasNavbar">
               <div className="border-bottom bg-white shadow-sm p-3">
                  <div className="d-flex align-items-center">
                     <div className="gap-3 d-flex align-items-center">
                        <button type="button" className="border-0 bg-white" data-bs-dismiss="offcanvas" aria-label="Close"><i className="bi bi-arrow-left d-flex text-success h3 m-0 back-page"></i></button>
                        <button type="button" className="btn bg-none border-0 p-0" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                        <span className="text-success h4 m-0"><i className="fa-solid fa-location-dot"></i>&nbsp;<span>Set
                        Location</span>&nbsp;<i className="fa-solid fa-chevron-down"></i>
                        </span>
                        </button>
                     </div>
                     <div className="ms-auto gap-2 d-flex align-items-center">
                        <a href="notifications.html" className="link-dark"><i className="bi bi-bell-fill d-flex m-0 h3 header-bell"></i></a>
                        <a href="/" className="link-dark"><i className="bi bi-house d-flex m-0 h2 header-bell"></i></a>
                     </div>
                  </div>
               </div>
               <div className="offcanvas-body">
                  <div className="bg-white sticky-top">
                     <h4 className="fw-bold mb-1">Your profile</h4>
                     <p>Log in or sign up to view your complete..</p>
                     <a href="signup.html" className="btn btn-outline-danger d-grid">Continue</a>
                  </div>
                  <hr/>
                  <div className="d-flex justify-content-between">
                     <div className="text-center">
                        <a href="signup.html" className="text-decoration-none link-dark">
                           <span><i className="fa-solid fa-heart h3"></i></span>
                           <p className="m-0">Likes</p>
                        </a>
                     </div>
                     <div className="text-center">
                        <a href="notifications.html" className="text-decoration-none link-dark">
                           <span><i className="fa-solid fa-bell h3"></i></span>
                           <p className="m-0">Notifications</p>
                        </a>
                     </div>
                     <div className="text-center">
                        <a href="setting.html" className="text-decoration-none link-dark">
                           <span><i className="fa-solid fa-gear h3"></i></span>
                           <p className="m-0">Settings</p>
                        </a>
                     </div>
                     <div className="text-center">
                        <a href="payment.html" className="text-decoration-none link-dark">
                           <span><i className="fa-solid fa-credit-card h3"></i></span>
                           <p className="m-0">Payments</p>
                        </a>
                     </div>
                  </div>
                  <hr/>
                  <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                     <li className="text-muted text-uppercase small mb-2">food orders</li>
                     <li className="nav-item">
                        <a className="nav-link" aria-current="page" href="your-order.html">
                        <span><i className="fa-solid fa-bag-shopping cw-20 text-center me-1"></i></span>&nbsp;Your Orders
                        </a>
                     </li>
                     <li className="nav-item">
                        <a className="nav-link" href="your-order.html">
                        <span><i className="fa-solid fa-heart cw-20 text-center me-1"></i></span>&nbsp;Favorite Order
                        </a>
                     </li>
                     <li className="nav-item">
                        <a className="nav-link" href="your-order.html">
                        <span><i className="fa-solid fa-address-book cw-20 text-center me-1"></i></span>&nbsp;Address Book
                        </a>
                     </li>
                     <li className="nav-item">
                        <a className="nav-link pb-0" href="support.html">
                        <span><i className="fa-solid fa-receipt cw-20 text-center me-1"></i></span>&nbsp;Online Ordering Help
                        </a>
                     </li>
                     <li>
                        <hr/>
                     </li>
                     <li className="nav-item">
                        <a className="nav-link py-0" href="your-order.html">
                        <span><i className="fa-solid fa-circle-info cw-20 text-center me-1"></i></span>&nbsp;About
                        </a>
                     </li>
                  </ul>
                  <hr/>
                  <div className="pb-5">
                     <ul className="navbar-nav">
                        <li className="nav-item">
                           <a className="nav-link" href="support.html">Send Feedback</a>
                        </li>
                        <li className="nav-item">
                           <a className="nav-link" href="support.html">Rate us on the Play Store</a>
                        </li>
                     </ul>
                  </div>
               </div>
            </div>
         </div>
      </nav>
      {/* <!-- Body --> */}
      <div>
         <div className="text-center p-4 mb-2">
            <i className="bi bi-wallet text-success fs-lg pt-5 pb-5"></i>
            <h1 className="fw-bold mb-1">Edition Wallet</h1>
            <p className="text-muted mb-4">Seamless one-click checkout for all payments on Groseri</p>
            <a href="offers.html" className="btn btn-success btn-lg d-grid rounded-pill">Activate Wallet</a>
         </div>
         <div className="row m-0 px-3 pb-5">
            <div className="col-6 px-1">
               <div className="text-center bg-white p-3 rounded-4">
                  <i className="fa-solid fa-bolt text-warning h1 m-0 cw-30 text-center"></i>
                  <h5 className="fw-bold mb-1 mt-3">Easy Checkout</h5>
                  <p className="text-muted m-0 small">Contrary to popular belief, Lorem Ipsum</p>
               </div>
            </div>
            <div className="col-6 px-1">
               <div className="text-center bg-white p-3 rounded-4">
                  <i className="fa-solid fa-shield text-success h1 m-0 cw-30 text-center"></i>
                  <h5 className="fw-bold mb-1 mt-3">Safe and Secure</h5>
                  <p className="text-muted m-0 small">There are many variations of passages</p>
               </div>
            </div>
         </div>
      </div>
      <div className="pt-4 pb-5"></div>
      {/* <!-- Fixed Bottom --> */}
      <div className="fixed-bottom bg-white shadow px-3 py-2 osahan-nav-bottom">
         <div className="row links">
            <div className="col osahan-nav-bottom-link text-center">
               <a href="your-order.html">
                  <span><i className="bi bi-truck h1"></i></span>
                  <p className="m-0">Delivery</p>
               </a>
            </div>
            <div className="col osahan-nav-bottom-link p-0 text-center active">
               <a href="offers.html">
                  <span><i className="bi bi-percent h1"></i></span>
                  <p className="m-0">Offers</p>
               </a>
            </div>
            <div className="col osahan-nav-bottom-link osahan-nav-bottom-link-center">
               <a className="osahan-nav-bottom-home" href="/">
               <span><i className="bi bi-house-fill h1"></i></span>
               </a>
            </div>
            <div className="col osahan-nav-bottom-link p-0 text-center">
               <a href="cart.html">
                  <span><i className="bi bi-basket h1"></i></span>
                  <p className="m-0">Cart</p>
               </a>
            </div>
            <div className="col osahan-nav-bottom-link p-0 text-center">
               <a href="your-order.html">
                  <span><i className="bi bi-receipt h1"></i></span>
                  <p className="m-0">History</p>
               </a>
            </div>
         </div>
      </div>
      {/* <!-- Model --> */}
      {/* <!-- location Modal --> */}
      <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
         <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered">
            <div className="modal-content rounded-4 border-0">
               <div className="modal-header p-4 border-0">
                  <h4 className="modal-title" id="staticBackdropLabel">Add Your Location</h4>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div className="modal-body pt-0 px-4 pb-4">
                  <form>
                     <div className="input-group border rounded-3 overflow-hidden mb-3">
                        <span className="input-group-text bg-white border-0 px-3 py-2"><i className="bi bi-search"></i></span>
                        <input type="text" className="shadow-none form-control bg-white border-0 ps-0" placeholder="Enter Location"/>
                     </div>
                  </form>
                  <div data-bs-dismiss="modal"  className="d-flex align-items-center gap-3 mb-3 px-0">
                     <div><i className="fa-solid fa-location-crosshairs text-danger"></i></div>
                     <div>Use Current Location</div>
                  </div>
                  <div className="text-muted text-uppercase small mb-1 pt-4">Search Results</div>
                  <div data-bs-dismiss="modal" className="d-flex align-items-center gap-3">
                     <span><i className="bi bi-search h6"></i></span>
                     <div className="lh-sm border-bottom py-3 w-100">
                        <h6 className="mb-1">Bangalore</h6>
                        <p className="text-muted m-0">Karnataka, India</p>
                     </div>
                  </div>
                  <div data-bs-dismiss="modal" className="d-flex align-items-center gap-3">
                     <span><i className="bi bi-search h6"></i></span>
                     <div className="lh-sm border-bottom py-3 w-100">
                        <h6 className="mb-1">Bangalore internaltional airport</h6>
                        <p className="text-muted m-0">Karmpegowda.in't Airport, Hunachur, karnataka, India</p>
                     </div>
                  </div>
                  <div data-bs-dismiss="modal" className="d-flex align-items-center gap-3">
                     <span><i className="bi bi-search h6"></i></span>
                     <div className="lh-sm border-bottom py-3 w-100">
                        <h6 className="mb-1">Railway Station back gate</h6>
                        <p className="text-muted m-0">M.G. Railway Colony, Majestic, Bangaluru</p>
                     </div>
                  </div>
                  <div data-bs-dismiss="modal" className="d-flex align-items-center gap-3">
                     <span><i className="bi bi-search h6"></i></span>
                     <div className="lh-sm pt-3 w-100">
                        <h6 className="mb-1">Bangalore Cant</h6>
                        <p className="text-muted m-0">Cantonent Railway Station Road, Contonment Railway</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
    </>
  )
}
