import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './SellCarousel.css';

const SellCarousel = ({ width, data }) => {

    // const data = [restaurant1, restaurant2, restaurant3, restaurant4, restaurant5];
    // console.log(data);
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
    };


    return (
        <div style={{ width: '100%', marginVertical: 10 }}>
            <Slider {...settings} dots={false}> {/* Set dots to false */}
                {
                    data.map((item, index) => {
                        return (
                            <div key={index} style={{ margin: '0 auto' }}>
                                <img
                                    src={`https://api.veggieking.pk/public/upload/${item}`}
                                    alt={item}
                                    style={{
                                        width: `${width - 32}px`,
                                        height: `${width / 5}px`,
                                        borderRadius: '15px',
                                        border: '1px solid #ccc',
                                    }}
                                />
                            </div>
                        )
                    })
                }
            </Slider>
        </div>

    );
};

export default SellCarousel;