import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Location from "./Components/Location";
import Home from "./Components/Home/Home";
import Notify from "./Components/Notification/Notify";
import SearchList from "./Components/SearchListProduct/SearchList";
import Store from "./Components/Store/Store";
import YourOrders from "./Components/YourOrders/YourOrders";
import Offers from "./Components/Offers/Offers";
import Cart from "./Components/Cart/Cart";
import EnableLocation from "./Components/EnableLocation/EnableLocation";
import Locations from "./Components/Locations/Locations";
import PersonalInfo from "./Components/PersonalInfo/PersonalInfo";
import PreLoader from "./Components/PreLoader/PreLoader";
import Loader from "./Components/Includes/Loader";
import SignUp from "./Components/Authentication/SignUp";
import OtpVerify from "./Components/Authentication/OtpVerify";
import Coupons from "./Components/Coupons/Coupons";
import Wallet from "./Components/Wallet/Wallet";
import NotificationSetting from "./Components/NotificationSetting/NotificationSetting";
import CheckOut from "./Components/CheckOut/CheckOut";
import Payment from "./Components/Payment/Payment";
import OrderConfirm from "./Components/OrderConfirm/OrderConfirm";
import OrderDetails from "./Components/OrderDetails/OrderDetails";
import PrivacyPolicy from "./Components/PrivacyPolicy/PrivacyPolicy";
import CategoryProducts from "./Components/Offers/CategoryProducts";
import { CartProvider, useCart } from './context/CartContext';
import SignIn from "./Components/Authentication/SignIn";
import Forgot from "./Components/Authentication/Forgot";
import { useEffect, useState } from "react";
import Logout from "./Components/Authentication/Logout";
import ChangePassword from "./Components/PersonalInfo/ChangePassword";
import ContactUs from "./Components/PersonalInfo/ContactUs";
import RiderOrders from "./Components/Store/RiderOrders";
import RiderOrderDetails from "./Components/OrderDetails/RiderOrderDetails";
import RiderUserDetails from "./Components/OrderDetails/RiderUserDetails";
import secureLocalStorage from "react-secure-storage";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import ProductDetail from "./Components/SearchListProduct/ProductDetail";
import ResetPassword from "./Components/PersonalInfo/ResetPassword";


function App() {
  const id = secureLocalStorage.getItem("id");
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true); // Set initial loading state to true

  // const { appLoader } = useCart();

  useEffect(() => {
    // Simulate loading delay
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timeout);
  }, []); // Empty dependency array ensures this effect runs only once

  console.log(id);
  useEffect(() => {
    if (id) {
      setAuthenticated(true);
    } else {
      setAuthenticated(false);
    }
  }, [id, authenticated]);

  const handleSignOut = () => {
    setAuthenticated(false);
  };

  return (
    <div className="App">
      {/* <PreLoader/> */}
      <BrowserRouter>
        {loading ? (
          <Loader />
        ) : (
          <CartProvider>
            <ToastContainer />
            <Routes>
              <Route path="/sign-in" element={
                authenticated ? (
                  <Navigate to="/" replace />
                ) : (
                  // <SignIn onSignIn={handleSignIn} authenticated={authenticated} />
                  <SignIn />
                )
              } />

              <Route path="/logout" element={<Logout />} />
              <Route path="/sign-up" element={<SignUp />} />
              <Route path="/forgot-password" element={<Forgot />} />
              <Route path="/" element={<Home />} />
              {/* <Route path="/dashboard" element={authenticated ? <Home /> : <Navigate to="/" />} /> */}
              {/* <Route path="/dashboard" element={<Home />} /> */}
              <Route path="/location" element={<Location />} />
              <Route path="/notifications" element={<Notify />} />
              <Route path="/search-list" element={<SearchList />} />
              <Route path="/orders" element={<Store />} />
              <Route path="/product-detail" element={<ProductDetail />} />
              <Route path="/rider-orders" element={<RiderOrders />} />
              <Route path="/your-order" element={<YourOrders />} />
              <Route path="/offers" element={<Offers />} />
              {/* <Route path="/category-products/:id/:name" element={<CategoryProducts />} /> */}
              <Route path="/category-products" element={<CategoryProducts />} />
              <Route path="/cart-old" element={<Cart />} />
              <Route path="/enable-location" element={<EnableLocation />} />
              <Route path="/locations" element={<Locations />} />
              <Route path="/personal-info" element={<PersonalInfo />} />
              <Route path="/change-password" element={<ChangePassword />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="/otp-verify" element={<OtpVerify />} />
              <Route path="/coupons" element={<Coupons />} />
              <Route path="/wallet" element={<Wallet />} />
              <Route path="/notification-setting" element={<NotificationSetting />} />
              <Route path="/cart" element={<CheckOut />} />
              <Route path="/payment" element={<Payment />} />
              <Route path="/order-confirm" element={<OrderConfirm />} />
              <Route path="/order-details" element={<OrderDetails />} />
              <Route path="/rider-order-details" element={<RiderOrderDetails />} />
              <Route path="/rider-user-details" element={<RiderUserDetails />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            </Routes>
          </CartProvider>
        )}
      </BrowserRouter>
    </div>
  );
}

export default App;
