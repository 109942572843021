import React from "react";
import { Link } from "react-router-dom";

export default function SearchList() {
  return (
    <>
      <div className="bg-white shadow-sm px-4 pt-3">
        <div className="d-flex align-items-center">
          <div className="gap-3 d-flex align-items-center">
            <a href="/">
              <i className="bi bi-arrow-left d-flex text-success h3 m-0 back-page"></i>
            </a>
            <h3 className="fw-bold m-0">Search List</h3>
          </div>
          <div className="ms-auto gap-2 d-flex align-items-center">
            <a href="notifications.html" className="link-dark">
              <i className="bi bi-bell-fill d-flex m-0 h3 header-bell"></i>
            </a>
            <a className="toggle osahan-toggle text-dark m-0" href="#">
              <i className="bi bi-list d-flex m-0 h3 header-list"></i>
            </a>
          </div>
        </div>
      </div>
      <div className="sticky-top bg-white py-3 px-4">
        <div className="input-group bg-white rounded-pill border p-0 overflow-hidden">
          <a
            href="/"
            className="input-group-text text-decoration-none text-danger border-0 border-end bg-white pe-0"
          >
            <i className="fa-solid fa-arrow-left"></i>
          </a>
          <input
            type="text"
            className="shadow-none form-control border-0 border-end"
            value="Meat and Seafood"
          />
          <a
            href="#"
            className="input-group-text text-decoration-none border-0 bg-white"
          >
            <i className="fa-solid fa-xmark text-danger"></i>
          </a>
        </div>
      </div>
      <div className="p-4">
        <div className="d-flex align-items-center justify-content-between mb-3">
          <h3 className="fw-bold mb-0">All Stores</h3>
          <button
            type="button"
            className="btn btn-white bg-white border rounded-pill btn-sm"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdrop2"
          >
            <span>
              <i className="fa-solid fa-arrows-up-down me-1 small"></i>
            </span>
            Relevance
          </button>
        </div>
        <div className="row g-3">
          {/* <!-- 1st product --> */}
          <div className="col-6 col-md-4">
            <Link to="/store" className="text-decoration-none link-dark">
              <div className="card rounded-4 shadow border-0 overflow-hidden search-list-item">
                <div className="position-relative">
                  <div className="product-veg bg-success  opacity-75 text-center text-white p-2">
                    <span>
                      <i className="fa-brands fa-envira"></i>
                    </span>
                    &nbsp;Pure Veg
                  </div>
                  <img src="img/1.jpeg" alt="" className="img-fluid rounded-top" />
                </div>
                <div className="card-body">
                  <div className="mb-1">
                    <div>
                      <span className="badge bg-success rounded-pill">
                        4.5&nbsp;<i className="fa-solid fa-star"></i>
                      </span>
                    </div>
                    <div className="h4 fw-bold m-0">Ginger (Adrak)</div>
                  </div>
                  <div className="d-flex gap-2 text-muted m-0 fw-normal add-footer">
                    <div className="add-btn">
                      <i className="bi bi-plus-lg"></i>
                    </div>
                    <div>500g</div>
                    <div>$150</div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          {/* <!-- 2nd product --> */}
          <div className="col-6 col-md-4">
            <Link to="/store" className="text-decoration-none link-dark">
              <div className="card rounded-4 shadow border-0 overflow-hidden search-list-item">
                <div className="position-relative">
                  <img src="img/6.jpeg" alt="" className="img-fluid rounded-top" />
                </div>
                <div className="card-body">
                  <div className="mb-1">
                    <div>
                      <span className="badge bg-success rounded-pill">
                        4.2&nbsp;<i className="fa-solid fa-star"></i>
                      </span>
                    </div>
                    <div className="h4 fw-bold m-0">White Onion</div>
                  </div>
                  <div className="d-flex gap-2 text-muted m-0 fw-normal add-footer">
                    <div className="add-btn">
                      <i className="bi bi-plus-lg"></i>
                    </div>
                    <div>2kg</div>
                    <div>$100</div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          {/* <!-- 3rd product --> */}
          <div className="col-6 col-md-4">
            <Link to="/store" className="text-decoration-none link-dark">
              <div className="card rounded-4 shadow border-0 overflow-hidden search-list-item">
                <div className="position-relative">
                  <img src="img/7.jpeg" alt="" className="img-fluid rounded-top" />
                </div>
                <div className="card-body">
                  <div className="mb-1">
                    <div>
                      <span className="badge bg-success rounded-pill">
                        4.4&nbsp;<i className="fa-solid fa-star"></i>
                      </span>
                    </div>
                    <div className="h4 fw-bold m-0">Celery</div>
                  </div>
                  <div className="d-flex gap-2 text-muted m-0 fw-normal add-footer">
                    <div className="add-btn">
                      <i className="bi bi-plus-lg"></i>
                    </div>
                    <div>150g</div>
                    <div>$170</div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          {/* <!-- 4rth product --> */}
          <div className="col-6 col-md-4">
            <Link to="/store" className="text-decoration-none link-dark">
              <div className="card rounded-4 shadow border-0 overflow-hidden search-list-item">
                <div className="position-relative">
                  <img src="img/8.jpeg" alt="" className="img-fluid rounded-top" />
                </div>
                <div className="card-body">
                  <div className="mb-1">
                    <div>
                      <span className="badge bg-success rounded-pill">
                        4.4&nbsp;<i className="fa-solid fa-star"></i>
                      </span>
                    </div>
                    <div className="h4 fw-bold m-0">Tomato Soup</div>
                  </div>
                  <div className="d-flex gap-2 text-muted m-0 fw-normal add-footer">
                    <div className="add-btn">
                      <i className="bi bi-plus-lg"></i>
                    </div>
                    <div>1kg</div>
                    <div>$200</div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          {/* <!-- 5th product --> */}
          <div className="col-6 col-md-4">
            <Link to="/store" className="text-decoration-none link-dark">
              <div className="card rounded-4 shadow border-0 overflow-hidden search-list-item">
                <div className="position-relative">
                  <img src="img/9.jpeg" alt="" className="img-fluid rounded-top" />
                </div>
                <div className="card-body">
                  <div className="mb-1">
                    <div>
                      <span className="badge bg-success rounded-pill">
                        3.6&nbsp;<i className="fa-solid fa-star"></i>
                      </span>
                    </div>
                    <div className="h4 fw-bold m-0">Garlic (Lehsun)</div>
                  </div>
                  <div className="d-flex gap-2 text-muted m-0 fw-normal add-footer">
                    <div className="add-btn">
                      <i className="bi bi-plus-lg"></i>
                    </div>
                    <div>500g</div>
                    <div>$150</div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          {/* <!-- 6th product --> */}
          <div className="col-6 col-md-4">
            <Link to="/store" className="text-decoration-none link-dark">
              <div className="card rounded-4 shadow border-0 overflow-hidden search-list-item">
                <div className="position-relative">
                  <img src="img/10.jpeg" alt="" className="img-fluid rounded-top" />
                </div>
                <div className="card-body">
                  <div className="mb-1">
                    <div>
                      <span className="badge bg-success rounded-pill">
                        3.4&nbsp;<i className="fa-solid fa-star"></i>
                      </span>
                    </div>
                    <div className="h4 fw-bold m-0">Onion</div>
                  </div>
                  <div className="d-flex gap-2 text-muted m-0 fw-normal add-footer">
                    <div className="add-btn">
                      <i className="bi bi-plus-lg"></i>
                    </div>
                    <div>500g</div>
                    <div>$200</div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
      {/* <!-- Sort by --> */}
      {/* <!-- Modal --> */}
      <div
        className="modal fade"
        id="staticBackdrop2"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-dialog-centered">
          <div className="modal-content border-0 rounded-4">
            <div className="modal-header px-4 pt-4 pb-0 border-0">
              <h5 className="modal-title" id="staticBackdropLabel">
                Sort by
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body p-4">
              <div className="form-check d-flex justify-content-between mb-3 px-0 fs-6">
                <label className="form-check-label" for="flexRadioDefault1">
                  Relevance
                </label>
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                  checked
                />
              </div>
              <div className="form-check d-flex justify-content-between mb-3 px-0 fs-6">
                <label
                  className="form-check-label text-muted"
                  for="flexRadioDefault2"
                >
                  Rating: High To Low
                </label>
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                />
              </div>
              <div className="form-check d-flex justify-content-between mb-3 px-0 fs-6">
                <label
                  className="form-check-label text-muted"
                  for="flexRadioDefault3"
                >
                  Cost: Low To High
                </label>
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault3"
                />
              </div>
              <div className="form-check d-flex justify-content-between mb-0 px-0 fs-6">
                <label
                  className="form-check-label text-muted"
                  for="flexRadioDefault4"
                >
                  Cost: High To Low
                </label>
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault4"
                />
              </div>
            </div>
            <div className="modal-footer px-4 pb-4 pt-0 border-0">
              <button
                type="button"
                className="btn btn-white text-danger w-50 m-0"
                data-bs-dismiss="modal"
              >
                Clear All
              </button>
              <button
                type="button"
                className="btn btn-danger rounded-md w-50 m-0"
                data-bs-dismiss="modal"
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
