import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Footer from '../Includes/Footer'
import GeneralService from '../../services/general.service';

export default function OrderDetails() {
   const location = useLocation();
   const { orderId } = location.state || {};
   const [isLoading, setIsLoading] = useState(false)
   const [detailData, setDetailData] = useState([]);
   console.log(location.state);
   useEffect(() => {
      const getOrderDetail = async (id) => {
         try {
            setIsLoading(true);
            const ordersData = await GeneralService.listOrdersDetailByOrderId(id);
            const { data } = ordersData;
            const { response } = data;
            console.log(`detail-data=${JSON.stringify(response)}`);
            setIsLoading(false);
            setDetailData(response);
         } catch (err) {
            console.log(err);
            setIsLoading(false);
            setDetailData([]);
         }
      }

      getOrderDetail(orderId);
   }, [orderId, location]);

   return (
      <>
         <div className="osahan-header-main py-3 px-4">
            <div className="d-flex align-items-center">
               <div className="gap-3 d-flex align-items-center">
                  <Link to="/">
                     <i className="bi bi-arrow-left d-flex text-success h3 m-0 back-page"></i>
                  </Link>
                  <h3 className="fw-bold m-0">Order Details</h3>
               </div>
            </div>
         </div>
         {detailData.map(res => {
            return (
               <div className="mx-4 mb-3 bg-white rounded-4 shadow p-4">
                  <div className="m-0">
                     <div className="d-flex align-items-center gap-3 mb-3">
                        <img
                           height="50px"
                           src={`https://api.veggieking.pk/public/upload/${res.prod_image}`}
                           alt={res.prod_image}
                           className="img-fluid ch-20"
                        />
                        <div className="lh-sm flex-grow-1">
                           <h4 className="mb-1">{res.prod_name}</h4>
                           <div className="text-muted fw-normal">
                              Quantity: {res.quantity} | Rs. {res.prod_price}
                           </div>
                        </div>
                        <div className="fs-5 fw-bold ms-auto">
                           Rs. {parseInt(res.quantity) * parseInt(res.prod_price)}
                        </div>
                     </div>
                  </div>
               </div>
            )
         })}

         <div className="pb-5 pt-5"></div>
         <Footer />
      </>
   )
}
