import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useCart } from '../../context/CartContext';
import GeneralService from '../../services/general.service';
import Footer from '../Includes/Footer';
import secureLocalStorage from 'react-secure-storage';
import { toast } from 'react-toastify';
import './CheckOut.css';
import Swal from 'sweetalert2';
import Spinner from '../Includes/Spinner';

export default function CheckOut() {
    const { totalPrice, updateCartCounter, increaseQty, removeItemFromCart, decreaseQty, userAddress, userInfo, cartItems } = useCart();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    // const [minAmount, setMinAmount] = useState(1000);
    const [address, setAddress] = useState("");
    const [inputError, setInputError] = useState('');
    const [deliveryCharges, setDeliveryCharges] = useState(0);
    const [minCharges, setMinCharges] = useState(0);
    const [cartCounter, setCartCounter] = useState(0);
    const [itemNo, setItemNo] = useState(0);

    useEffect(() => {
        const savedAddress = secureLocalStorage.getItem("address");
        if (savedAddress) {
            setAddress(savedAddress);
        }
    }, []);

    useEffect(() => {
        if (Array.isArray(cartItems)) {
            fetchData();
            fetchDeliveryCharges();
            fetchMinCharges();
        }
    }, [cartItems]);

    const fetchData = async () => {
        try {
            const totalPrice = cartItems.reduce((accumulator, currentValue) => {
                return accumulator + (currentValue.quantity * currentValue.price);
            }, 0);

            const numberOfItems = cartItems.length;

            setItemNo(numberOfItems);
        } catch (err) {
            console.log("Error");
        }
    };

    const fetchDeliveryCharges = async () => {
        try {
            const response = await GeneralService.getChargesByPrice(totalPrice);
            const { data } = response;
            console.log(data);
            const { response: res } = data;
            setDeliveryCharges(res.price);
        } catch (err) {
            setDeliveryCharges(0);
        }
    };

    const fetchMinCharges = async () => {
        try {
            const response = await GeneralService.getMinCharges();
            const { data } = response;
            const { response: res } = data;
            setMinCharges(res.price);
        } catch (err) {
            setMinCharges(0);
        }
    };

    const handleInputChange = (text) => {
        setAddress(text);
    };

    const placeOrder = async () => {
        if (!secureLocalStorage.getItem("id")) {
            // toast.error("Please log in to place an order.");
            Swal.fire({
                title: 'Error',
                text: "Please log in to place an order",
                icon: 'warning',
              });
            navigate("/sign-in", {
                state: {
                    page: "/cart"
                }
            });
        } else if (parseInt(totalPrice) >= parseInt(minCharges)) {
            if (address) {
                setInputError("");
            } else {
                setInputError("Please enter address");
            }

            navigate("/payment", {
                state: {
                    cart: cartItems,
                    total: totalPrice,
                    delivery: deliveryCharges,
                    items: itemNo,
                    address: address
                }
            });
        } else if (parseInt(totalPrice) < parseInt(minCharges)) {
            // toast.error(`Minimum order is Rs ${minCharges}`);
            Swal.fire({
                title: 'Error',
                text: `Minimum order is Rs ${minCharges}`,
                icon: 'warning',
              });
            if (address) {
                setInputError("");
            } else {
                setInputError("Please enter address");
            }
        }
    };

    return (
        <>
            <div className="osahan-header-main py-3 px-4">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col">
                            <div className="d-flex align-items-center">
                                <Link to="/" className="text-decoration-none">
                                    <i className="bi bi-arrow-left d-flex text-success h3 m-0 back-page"></i>
                                </Link>
                                <h3 className="fw-bold m-0 ms-3">Cart</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mx-4 mb-4 bg-white rounded-4 shadow overflow-hidden">
                {cartItems.length === 0 ? (
                    <div style={{ textAlign: "center" }}>
                        <div style={{ textAlign: "center" }}>No items in cart</div>
                        <Spinner />
                    </div>
                ) : (
                    <div>
                        {cartItems.map((item, index) => {
                            return (
                                <div key={index} className="bg-white p-2 border-bottom">
                                    <div>
                                        <div className="mb-3 d-flex gap-3">
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div
                                                    onClick={() => removeItemFromCart(item)}
                                                    style={{
                                                        display: 'inline-flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        padding: '8px',
                                                        borderRadius: '50%',
                                                        backgroundColor: '#f8f9fa',
                                                        cursor: 'pointer',
                                                        transition: 'background-color 0.3s'
                                                    }}
                                                    className="trash-button"
                                                >
                                                    <i className="fa-solid fa-trash-can text-success"></i>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <img
                                                    height="50px"
                                                    src={`https://api.veggieking.pk/public/upload/${item.image}`}
                                                    alt={item.name}
                                                    style={{ width: '100%', maxWidth: '50px', height: 'auto' }}
                                                />
                                                <div style={{ marginLeft: '10px', display: 'flex', flexDirection: 'column' }}>
                                                    <h6 className="fw-bold mb-1" style={{ margin: 0 }}>{item.name}</h6>
                                                    <p className="mb-0">Rs. {item.price * item.quantity}</p>
                                                </div>
                                            </div>
                                            <div className="ms-auto text-end cw-80" style={{ marginTop: "10px" }}>
                                                <div className="btn btn-white btn-sm border border-success px-2 rounded-pill overflow-hidden">
                                                    <div className="d-flex align-items-center justify-content-between gap-2">
                                                        <div onClick={() => decreaseQty(item)} className="minus">
                                                            <i className="fa-solid fa-minus text-success"></i>
                                                        </div>
                                                        <span className="shadow-none form-control text-center border-0 p-0 box">{item.quantity}</span>
                                                        <div onClick={() => {
                                                            if (parseInt(item.quantity) < parseInt(item.stock_available)) {
                                                                if (parseInt(item.quantity) < parseInt(item.max_qty)) {
                                                                    increaseQty(item)
                                                                }
                                                            }
                                                        }} className="plus">
                                                            <i className="fa-solid fa-plus text-success"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
            {cartItems.length > 0 && (
                <>
                    <div className="bg-white mx-4 mb-4 rounded-4 shadow overflow-hidden" style={{ marginBottom: "100px" }}>
                        <div className="px-4 pt-4">
                            <div className="d-flex flex-column">
                                {secureLocalStorage.getItem("id") && (
                                    <div className="d-flex gap-3 align-items-center" style={{ width: '100%' }}>
                                        <div style={{ flex: 1 }}>
                                            <label htmlFor="delivery_address" className="h5 mb-1">Delivery Address</label>
                                            <div className="text-muted">
                                                <input
                                                    onChange={(e) => handleInputChange(e.currentTarget.value)}
                                                    className="form-control"
                                                    type="text"
                                                    name="delivery_address"
                                                    id="delivery_address"
                                                    value={address}
                                                    placeholder="Enter your delivery address"
                                                    aria-describedby="deliveryAddressError"
                                                    style={{ width: '100%' }}
                                                />
                                                {inputError && (
                                                    <span id="deliveryAddressError" className="error">
                                                        {inputError}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}

                                <div className="h4 fw-bold mt-3" style={{ alignSelf: 'flex-start' }}>
                                    TOTAL: Rs. {totalPrice}
                                </div>
                            </div>
                        </div>

                        <div className="p-4" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <button
                                className="btn btn-success btn-lg d-grid rounded-pill"
                                onClick={placeOrder}
                            >
                                Place Order
                            </button>
                        </div>
                    </div>
                    <div className="pt-5 pb-5"></div>
                </>
            )}
            <Footer counter={cartCounter} />
        </>
    );
}
