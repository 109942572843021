import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../Includes/Footer';
import GeneralService from '../../services/general.service';
import { useFormik } from 'formik';
import { userProfile } from '../../schema';
import secureLocalStorage from 'react-secure-storage';
import { toast } from 'react-toastify';

export default function PersonalInfo() {
   const [emailError, setEmailError] = useState("");
   const [phoneError, setPhoneError] = useState("");
   const [isLoading, setIsLoading] = useState(false);
   const [isEnable, setIsEnable] = useState(true);

   const [profileInfo, setProfileInfo] = useState({
      name: "",
      address: "",
      phone: "",
      email: "",
   });

   useEffect(() => {
      getUserById();
   }, []);

   const getUserById = async () => {
      try {
         let userId = secureLocalStorage.getItem("id");
         const timeout = 8000;
         const response = await Promise.race([
            GeneralService.getUserById(userId),
            new Promise((_, reject) => setTimeout(() => reject(new Error('Request timeout')), timeout))
         ]);

         if (response) {
            const user = response.data.response;
            setProfileInfo({
               name: `${user.first_name} ${user.last_name}`,
               address: user.address,
               phone: user.phone,
               email: user.email,
            });
            secureLocalStorage.setItem("first_name", user.first_name);
            secureLocalStorage.setItem("last_name", user.last_name);
            secureLocalStorage.setItem("address", user.address);
            secureLocalStorage.setItem("phone", user.phone);
            secureLocalStorage.setItem("email", user.email);
         } else {
            throw new Error('No response from the server');
         }
      } catch (err) {
         toast.error("No response from server");
      }
   };

   const updateProfile = async (values) => {
      try {
         let userId = secureLocalStorage.getItem("id");

         setIsLoading(true);
         setIsEnable(false);
         const timeout = 8000;
         const response = await Promise.race([
            GeneralService.updateUserById(values, userId),
            new Promise((_, reject) => setTimeout(() => reject(new Error('Request timeout')), timeout))
         ]);

         if (response) {
            getUserById();
            toast.success('Information updated successfully');
         } else {
            throw new Error('No response from the server');
         }
         setIsLoading(false);
         setIsEnable(true);
      } catch (err) {
         setIsLoading(false);
         setIsEnable(true);
         setEmailError("");
         setPhoneError("");
         if (err?.response?.status === 422) {
            if (err?.response?.data?.email) {
               setEmailError(err?.response?.data?.email[0]);
            }
            if (err?.response?.data?.phone) {
               setPhoneError(err?.response?.data?.phone[0]);
            }
         } else {
            toast.error("Server error. Please try again later.");
         }
      }
   };

   const formik = useFormik({
      enableReinitialize: true,
      initialValues: {
         name: profileInfo.name,
         address: profileInfo.address,
         phone: profileInfo.phone,
         email: profileInfo.email,
      },
      validationSchema: userProfile,
      onSubmit: async (values, actions) => {
         try {
            const userId = secureLocalStorage.getItem("id");
            if (!userId) {
               throw new Error('User ID not found in localStorage');
            }
            await updateProfile(values);
            actions.resetForm();
         } catch (error) {
            console.error('Profile update failed', error.response ? error.response.data : error.message);
            toast.error('Profile update failed. Please try again.');
         } finally {
            actions.setSubmitting(false);
         }
      },
   });

   return (
      <>
         <div className="osahan-header-main py-3 px-4">
            <div className="container-fluid">
               <div className="row align-items-center">
                  <div className="col">
                     <div className="d-flex align-items-center">
                        <Link to="/" className="text-decoration-none">
                           <i className="bi bi-arrow-left d-flex text-success h3 m-0 back-page"></i>
                        </Link>
                        <h3 className="fw-bold m-0 ms-3">Personal Info</h3>
                     </div>
                  </div>
                  <div className="col-auto ms-auto">
                     <Link to="/logout" className="text-white text-decoration-none">
                        <i className="bi bi-box-arrow-right d-flex m-0 h3 header-bell"></i>
                     </Link>
                  </div>
               </div>
            </div>
         </div>

         <div className="mx-4 mb-4 bg-white rounded-4 shadow overflow-hidden p-4">
            <form noValidate onSubmit={formik.handleSubmit}>
               <div className="mb-3">
                  <label className="form-label text-muted">Name</label>
                  <input
                     id="name"
                     name="name"
                     onChange={formik.handleChange}
                     type="text"
                     className="shadow-none form-control"
                     value={formik.values.name || ""}
                     placeholder="Full Name"
                  />
               </div>
               <div className="mb-3">
                  <label className="form-label text-muted">Address</label>
                  <input
                     id="address"
                     name="address"
                     onChange={formik.handleChange}
                     type="text"
                     className="shadow-none form-control"
                     value={formik.values.address || ""}
                     placeholder="Address"
                  />
               </div>
               <div className="mb-3">
                  <label className="form-label text-muted">Phone</label>
                  <input
                     id="phone"
                     name="phone"
                     onChange={formik.handleChange}
                     type="text"
                     className="shadow-none form-control"
                     value={formik.values.phone || ""}
                     placeholder="Phone"
                  />
               </div>
               <div className="mb-3">
                  <label className="form-label text-muted">Email</label>
                  <input
                     id="email"
                     name="email"
                     onChange={formik.handleChange}
                     type="email"
                     className="shadow-none form-control"
                     value={formik.values.email || ""}
                     placeholder="Email"
                  />
               </div>

               <div className="d-grid">
                  <button type="submit" className="btn btn-success btn-lg rounded-pill">
                     Update
                  </button>
               </div>
            </form>
         </div>
         <Footer />
      </>
   );
}
