import React from 'react'
import { Link } from 'react-router-dom'
import Footer from '../Includes/Footer'
import { useFormik } from 'formik';
import { passwordProfile } from '../../schema';
import GeneralService from '../../services/general.service';
import secureLocalStorage from 'react-secure-storage';
import { toast } from 'react-toastify';

export default function ChangePassword() {
   const { values, errors, touched, handleBlur, handleChange, handleSubmit, resetForm } = useFormik({
      enableReinitialize: true,
      initialValues: {
         current_password: "",
         new_password: "",
         confirm_password: "",
      },
      validationSchema: passwordProfile,
      onSubmit: async (values, actions) => {
         try {
            const userId = secureLocalStorage.getItem("id");
            const response = await GeneralService.updatePasswordById(values.current_password, values.new_password, userId);
            toast.success("Password updated");
            // console.log('Sign up successful', response.data);
            // toast.success("Sign up successful!"); // Display success message
            resetForm(); // Reset the form fields
         } catch (error) {
            console.error('Sign up failed', error.response ? error.response.data : error.message);
            if (error?.response?.status === 422) {
               toast.error("Current Password is incorrect");
            } else {
               toast.error("Server error. Please try again later.");
            }
            // toast.error('Sign up failed. Please try again.'); // Show error message
         } finally {
            actions.setSubmitting(false);
         }
      },
   });

   return (
      <>
         <div className="osahan-header-main py-3 px-4">
            <div className="d-flex align-items-center">
               <div className="gap-3 d-flex align-items-center">
                  <Link to="/">
                     <i className="bi bi-arrow-left d-flex text-success h3 m-0 back-page"></i>
                  </Link>
                  <h3 className="fw-bold m-0">Change Password</h3>
               </div>
            </div>
         </div>
         <div className="mx-4 mb-4 bg-white rounded-4 shadow overflow-hidden p-4">
            <form noValidate onSubmit={handleSubmit}>
               <div className="mb-3">
                  <label className="form-label text-muted">Old Password</label>
                  <input
                     id="current_password"
                     name="current_password"
                     type="password"
                     className="shadow-none form-control"
                     onChange={handleChange}
                     value={values.current_password}
                     placeholder="Enter Current Password" />
                  {touched.current_password && errors.current_password && <p style={{ color: "red" }}>{errors.current_password}</p>}
               </div>
               <div className="mb-3">
                  <label className="form-label text-muted">New Password</label>
                  <input
                     id="new_password"
                     name="new_password"
                     type="password"
                     className="shadow-none form-control"
                     onChange={handleChange}
                     value={values.new_password}
                     placeholder="Enter New Password" />
                  {touched.new_password && errors.new_password && <p style={{ color: "red" }}>{errors.new_password}</p>}
               </div>
               <div className="mb-3">
                  <label className="form-label text-muted">Confirm Password</label>
                  <input
                     id="confirm_password"
                     name="confirm_password"
                     type="password"
                     className="shadow-none form-control"
                     onChange={handleChange}
                     value={values.confirm_password}
                     placeholder="Enter Confirm Password" />
                  {touched.confirm_password && errors.confirm_password && <p style={{ color: "red" }}>{errors.confirm_password}</p>}
               </div>

               <div className="d-grid">
                  <button type="submit" className="btn btn-success btn-lg rounded-pill">
                     Update
                  </button>
               </div>
            </form>
         </div>
         <Footer />
      </>
   )
}
