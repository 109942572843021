import { Link } from "react-router-dom"
import { useCart } from "../../context/CartContext";
import "./Footer.css";
import { useEffect } from "react";
import secureLocalStorage from "react-secure-storage";

const Footer = ({ counter }) => {
    const { cartCounter } = useCart();
    // console.log(`counter=${cartCounter}`);
    let userId = secureLocalStorage.getItem("id");

    let response = (
        <div className="fixed-bottom bg-white shadow px-3 py-2 osahan-nav-bottom">
            <div className="row links">
                <div className="col osahan-nav-bottom-link text-center">
                    <Link to="/">
                        <span>
                            <i className="bi bi-house h1"></i>
                        </span>
                        <p className="m-0">Home</p>
                    </Link>
                </div>
                {userId && (
                    <div className="col osahan-nav-bottom-link p-0 text-center">
                        <Link to="/orders">
                            <span>
                                <i className="bi bi-gift h1"></i>
                            </span>
                            <p className="m-0">Orders</p>
                        </Link>
                    </div>
                )}
                <div className="col osahan-nav-bottom-link p-0 text-center">
                    <Link to="/cart" style={{ position: "relative", display: "inline-block" }}>
                        <span>
                            <i className="bi bi-basket h1"></i>
                        </span>
                        <p className="m-0">Cart</p>
                        {/* {cartCounter} */}
                        {parseInt(cartCounter) > 0 && (<span style={{ color: "white" }} className="cart-counter">{cartCounter}</span>)}

                        {/* {counter} */}
                        {/* (<span style={{ color: "white" }} className="cart-counter">{cartCounter}</span>) */}
                    </Link>
                </div>

                <div className="col osahan-nav-bottom-link p-0 text-center">
                    <Link to={userId ? `/personal-info` : `/sign-in`}>
                        <span>
                            <i className="bi bi-person h1"></i>
                        </span>
                        <p className="m-0">Profile</p>
                    </Link>
                </div>
            </div>
        </div>
    )

    return (
        response
    )
}

export default Footer;