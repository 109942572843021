import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import thankyouGif from '../Images/thnkyou.gif'
import './OrderConfirm.css'
export default function OrderConfirm() {
   const location = useLocation();
   const { orderNo } = location.state || {};

   return (
      <>
         <div class="osahan-header-main py-3 px-4">
            <div class="d-flex align-items-center">
               <div class="gap-3 d-flex align-items-center">
                  <a href="/"><i class="bi bi-arrow-left d-flex text-success h3 m-0 back-page"></i></a>
                  <h3 class="fw-bold m-0">Your Order</h3>
               </div>
            </div>
         </div>
         <div class="my-5">
    <div class="text-center">
        <div class="px-5">
            <img src={thankyouGif} alt="Success Image" class="success-image pt-5 lh-sm" />
        </div>
        <div class="h1 text-success mb-1">Thank You</div>
        <div class="fs-5">Order Placed Successfully</div>
        <div class="fs-5">Your Order will reach soon</div>
        <div class="fs-5">Order ID {orderNo}</div>
        <br/>
        <div class="fs-5">For any query please contact us at 0346-5477825</div>
    </div>
</div>

         <div class="d-grid fixed-bottom gap-3 p-4">
            <Link to="/" class="btn btn-outline-success btn-lg rounded-pill">Dashboard</Link>
         </div>

      </>
   )
}
