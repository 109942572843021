import React, { useEffect, useState } from 'react';

export default function Location() {
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);

    useEffect(() => {
        const watchId = navigator.geolocation.watchPosition(
          (position) => {
            const newLatitude = position.coords.latitude;
            const newLongitude = position.coords.longitude;
            setLatitude(newLatitude);
            setLongitude(newLongitude);
          },
          (error) => {
            console.error('Error getting location:', error);
          },
          { enableHighAccuracy: true, maximumAge: 0 }
        );
        return () => {
          navigator.geolocation.clearWatch(watchId);
        };
      }, [latitude, longitude]);
      
    return (
        <>{`latitude=${latitude}, longitude=${longitude}`}</>
    );
}
