import React from 'react';
import Modal from 'react-modal';
import styled, { keyframes } from 'styled-components';
import { FaGift } from 'react-icons/fa';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const bounceIn = keyframes`
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${fadeIn} 0.5s ease-in-out;
`;

const GradientContainer = styled.div`
  background: linear-gradient(180deg, #FFEB34 0%, #E76F00 100%);
  height: 450px;
  width: 80%;
  max-width: 600px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  animation: ${bounceIn} 0.5s ease;

  @media (max-width: 768px) {
    width: 90%;
    height: auto;
    padding: 20px;
  }

  @media (max-width: 480px) {
    width: 95%;
    padding: 10px;
  }
`;

const Title = styled.h1`
  font-size: 36px;
  font-weight: bold;
  margin-top: 20px;
  color: white;
  display: flex;
  align-items: center;
  gap: 10px;

  @media (max-width: 768px) {
    font-size: 28px;
    margin-top: 10px;
  }

  @media (max-width: 480px) {
    font-size: 24px;
    margin-top: 5px;
  }
`;

const Code = styled.h2`
  font-size: 28px;
  font-weight: bold;
  color: white;
  margin: 30px 0;

  @media (max-width: 768px) {
    font-size: 24px;
    margin: 20px 0;
  }

  @media (max-width: 480px) {
    font-size: 20px;
    margin: 15px 0;
  }
`;

const Info = styled.p`
  font-size: 16px;
  color: white;

  @media (max-width: 768px) {
    font-size: 14px;
  }

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

const Button = styled.button`
  height: 62px;
  width: 72%;
  border-radius: 10px;
  border: none;
  background: linear-gradient(90deg, #FFD700, #FF8C00);
  color: white;
  font-size: 18px;
  cursor: pointer;
  margin-top: 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    height: 50px;
    font-size: 16px;
  }

  @media (max-width: 480px) {
    height: 40px;
    font-size: 14px;
  }
`;

const CustomModal = ({ heading, body, body2, button, modalVisible, setModalVisible, onPressGotIt }) => {
  return (
    <Modal
      isOpen={modalVisible}
      onRequestClose={() => setModalVisible(false)}
      style={{
        overlay: { backgroundColor: 'rgba(0, 0, 0, 0.2)' },
        content: { border: 'none', background: 'transparent', display: 'flex', alignItems: 'center', justifyContent: 'center' }
      }}
    >
      <GradientContainer>
        <Title><FaGift /> {heading}</Title>
        <Code>{body}</Code>
        <Info>{body2}</Info>
        <Button onClick={onPressGotIt}>{button}</Button>
      </GradientContainer>
    </Modal>
  );
};

export default CustomModal;
