import React, { useState } from 'react'
import { signInSchema, signUpSchema } from '../../schema';
import { Formik, Form, Field, ErrorMessage, useFormik } from 'formik';
import './SignUp.css'
import veggieLogo from '../Home/home-logo.png'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import GeneralService from '../../services/general.service';
import { useCart } from '../../context/CartContext';
import secureLocalStorage from 'react-secure-storage';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
export default function SignIn() {
  const location = useLocation();
  const { cart, address, items, delivery, total } = location.state || {};
  const { page } = location.state || "";
  const [isLoading, setIsLoading] = useState(false)
  const [isEnable, setIsEnable] = useState(true)
  const navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(false);

  const { updateCartCounter, updateUserAddress, updateUserInfo } = useCart();

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleLogin = async (values) => {
    try {
      setIsLoading(true);
      setIsEnable(false);
      const response = await GeneralService.login(values);
      const { data } = response;
      const { access_token, user } = data;
      const { id, user_type, first_name, last_name, address, phone, email } = user;
      secureLocalStorage.setItem("id", id);
      secureLocalStorage.setItem("user_type", user_type);
      secureLocalStorage.setItem("first_name", first_name);
      secureLocalStorage.setItem("last_name", last_name);
      secureLocalStorage.setItem("address", address);
      secureLocalStorage.setItem("phone", phone);
      secureLocalStorage.setItem("email", email);
      // updateUserInfo({
      //   id, user_type, first_name, last_name, address, phone, email
      // });
      // updateUserAddress(address);

      const cartResponse = await GeneralService.cartCounterByUserId(id);
      const { data: cartData } = cartResponse;
      const { response: cartNo } = cartData;
      console.log(user_type);
      updateCartCounter(cartNo);

      if (user_type === 'user') {
        if(page) {
          window.location.href = page;
        } else {
          navigate("/");
        }
      } else {
        navigate("/rider-orders");
      }

      setIsLoading(false);
      setIsEnable(true);
    } catch (err) {
      console.log(err)
      if (err?.response?.status == 401) {
        Swal.fire({
          title: 'Invalid Credentials',
          text: 'You entered wrong phone / password',
          icon: 'warning',
        });
      } else {
        Swal.fire({
          title: 'Server error',
          text: 'Please try again later',
          icon: 'warning',
        });
      }
      setIsLoading(false);
      setIsEnable(true);
    }
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,

      initialValues: {
        phone: "",
        password: ""
      },
      validationSchema: signInSchema,
      onSubmit: (values, action) => {
        // console.log(values);
        handleLogin(values);
      },
    });

  return (
    <div className="wrapper-new">
      <div className="container">
        <div className="logo-container">
          <img src={veggieLogo} alt="Logo" className="logo" />
        </div>
        <div className="signup-container">
          <div className="header">
            <h1>Log In</h1>
            <p>Please sign in to your existing account</p>
          </div>
          <form className="signup-form" noValidate onSubmit={handleSubmit}>
            <div>
              <label htmlFor="phone">PHONE NO</label>
              <input
                type="text"
                id="phone"
                name="phone"
                placeholder="Enter Phone No"
                onChange={handleChange}
                value={values.phone} />
              {touched.phone && errors.phone && (
                <span className="error">
                  {errors.phone}
                </span>
              )}
            </div>
            <div>
              <label htmlFor="password">PASSWORD</label>
              <div className="password-container">
                <input
                  type={passwordVisible ? "text" : "password"}
                  id="password"
                  name="password"
                  placeholder="Enter Password"
                  onChange={handleChange}
                  value={values.password}
                />
                {touched.password && errors.password && (
                  <span className="error">
                    {errors.password}
                  </span>
                )}

                <button
                  type="button"
                  className="toggle-password"
                  onClick={togglePasswordVisibility}
                >
                  {passwordVisible ? '🙈' : '👁️'}
                </button>
              </div>
            </div>
            <div className='forgot-side'>
              <Link to="/forgot-password" className='forgot'>Forgot Password ?</Link>
            </div>
            <button type="submit" className="signup-button">Log In</button>
            <div className='signup'>
              <span>Don't have an account? <Link to="/sign-up">Sign Up</Link></span>
            </div>
          </form>
        </div>
      </div>
    </div>

  )
}