import React from "react";

export default function Offers() {
   return (
      <>
         {/* <!-- Navbar --> */}
         <div className="bg-light shadow-sm px-4 pt-3">
            <div className="d-flex align-items-center">
               <div className="gap-3 d-flex align-items-center">
                  <a href="/"><i className="bi bi-arrow-left d-flex text-success h3 m-0 back-page"></i></a>
                  <button type="button" className="btn bg-none border-0 p-0" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                     <span className="text-success h4 m-0"><i className="fa-solid fa-location-dot"></i>&nbsp;<span>Set
                        Location</span>&nbsp;<i className="fa-solid fa-chevron-down"></i>
                     </span>
                  </button>
               </div>
               <div className="ms-auto gap-2 d-flex align-items-center">
                  <a href="notifications.html" className="link-dark"><i className="bi bi-bell-fill d-flex m-0 h3 header-bell"></i></a>
                  <a className="toggle osahan-toggle text-dark m-0" href="#"><i className="bi bi-list d-flex m-0 h3 header-list"></i></a>
               </div>
            </div>
         </div>
         {/* <!-- Search bar --> */}
         <div className="sticky-top bg-light py-3 px-4">
            <div className="input-group bg-white overflow-hidden rounded-pill border p-1 overflow-hidden">
               <span className="input-group-text border-0 bg-white"><i
                  className="fa-solid fa-magnifying-glass text-danger"></i></span>
               <input type="text" className="shadow-none form-control border-0 ps-1" placeholder="Search Items"
               />
            </div>
         </div>
         {/* <!-- Body --> */}
         <div className="ps-4 py-4 bg-white pe-1">
            <div className="d-flex align-items-center justify-content-between mb-3 pe-4">
               <div className="d-flex align-items-center gap-2">
                  <div><img src="img/discount.png" alt="" className="img-fluid ch-20 cw-20" /></div>
                  <div>
                     <h5 className="fw-bold mb-0">Minimum 50% OFF</h5>
                     <p className="text-muted m-0">and other amazing offers too</p>
                  </div>
               </div>
               <div><a href="search-list.html" className="text-decoration-none text-danger fw-bold">See all</a></div>
            </div>
            <div className="px-0">
               <div className="multipleitems">
                  {/* <!-- 1st slider --> */}
                  <div className="item pe-3">
                     <a href="search-list.html" className="text-decoration-none link-dark">
                        <div className="card bg-white border-0">
                           <div className="product-offer-back rounded-4 border overflow-hidden"><img
                              src="img/o1.jpeg"
                              alt="" className="img-fluid rounded-md" /></div>
                           {/* <!-- product time  --> */}
                           <div className="product-rating shadow-sm">
                              <span className="badge bg-success rounded-pill">4.2<i className="fa-solid fa-star"></i></span>
                           </div>
                           <div className="card-body px-0 pb-0 pt-2">
                              <h5 className="fw-bold mb-0">Matar Paneer Combo</h5>
                              <p className="text-muted m-0 mt-1 small">Combo of 5</p>
                           </div>
                        </div>
                     </a>
                  </div>
                  {/* <!-- 2nd --> */}
                  <div className="item pe-3">
                     <a href="search-list.html" className="text-decoration-none link-dark">
                        <div className="card bg-white border-0">
                           <div className="product-offer-back rounded-4 border overflow-hidden"><img
                              src="img/4.jpeg"
                              alt="" className="img-fluid rounded-md" /></div>
                           {/* <!-- product time  --> */}
                           <div className="product-rating shadow-sm">
                              <span className="badge bg-success rounded-pill">4.3<i className="fa-solid fa-star"></i></span>
                           </div>
                           <div className="card-body px-0 pb-0 pt-2">
                              <h5 className="fw-bold mb-0">Detox Juice Combo</h5>
                              <p className="text-muted m-0 mt-1 small">500 g - 700 g</p>
                           </div>
                        </div>
                     </a>
                  </div>
                  {/* <!-- 3rd --> */}
                  <div className="item pe-3">
                     <a href="search-list.html" className="text-decoration-none link-dark">
                        <div className="card bg-white border-0">
                           <div className="product-offer-back rounded-4 border overflow-hidden"><img
                              src="img/o1.jpeg"
                              alt="" className="img-fluid rounded-md" /></div>
                           {/* <!-- product time  --> */}
                           <div className="product-rating shadow-sm">
                              <span className="badge bg-warning">3.0<i className="fa-solid fa-star"></i></span>
                           </div>
                           <div className="card-body px-0 pb-0 pt-2">
                              <h5 className="fw-bold mb-0">Chinese Combo</h5>
                              <p className="text-muted m-0 mt-1 small">Combo of 4</p>
                           </div>
                        </div>
                     </a>
                  </div>
                  {/* <!-- 4rth --> */}
                  <div className="item pe-3">
                     <a href="search-list.html" className="text-decoration-none link-dark">
                        <div className="card bg-white border-0">
                           <div className="product-offer-back rounded-4 border overflow-hidden"><img
                              src="img/o2.jpeg"
                              alt="" className="img-fluid rounded-md" /></div>
                           {/* <!-- product time  --> */}
                           <div className="product-rating shadow-sm">
                              <span className="badge bg-success rounded-pill">4.0<i className="fa-solid fa-star"></i></span>
                           </div>
                           <div className="card-body px-0 pb-0 pt-2">
                              <h5 className="fw-bold mb-0">Matar Paneer Combo</h5>
                              <p className="text-muted m-0 mt-1 small">Combo of 5</p>
                           </div>
                        </div>
                     </a>
                  </div>
                  {/* <!-- 5th --> */}
                  <div className="item pe-3">
                     <a href="search-list.html" className="text-decoration-none link-dark">
                        <div className="card bg-white border-0">
                           <div className="product-offer-back rounded-4 border overflow-hidden"><img
                              src="img/o3.jpeg"
                              alt="" className="img-fluid rounded-md" /></div>
                           {/* <!-- product time  --> */}
                           <div className="product-rating shadow-sm">
                              <span className="badge bg-success rounded-pill">4.0<i className="fa-solid fa-star"></i></span>
                           </div>
                           <div className="card-body px-0 pb-0 pt-2">
                              <h5 className="fw-bold mb-0">Detox Juice Combo</h5>
                              <p className="text-muted m-0 mt-1 small">500 g - 700 g</p>
                           </div>
                        </div>
                     </a>
                  </div>
                  {/* <!-- 6th --> */}
                  <div className="item pe-3">
                     <a href="search-list.html" className="text-decoration-none link-dark">
                        <div className="card bg-white border-0">
                           <div className="product-offer-back rounded-4 border overflow-hidden"><img
                              src="img/o4.jpeg"
                              alt="" className="img-fluid rounded-md" /></div>
                           {/* <!-- product time  --> */}
                           <div className="product-rating shadow-sm">
                              <span className="badge bg-success rounded-pill">4.0<i className="fa-solid fa-star"></i></span>
                           </div>
                           <div className="card-body px-0 pb-0 pt-2">
                              <h5 className="fw-bold mb-0">Chinese Combo</h5>
                              <p className="text-muted m-0 mt-1 small">Combo of 4</p>
                           </div>
                        </div>
                     </a>
                  </div>
               </div>
            </div>
         </div>
         {/* <!-- Best offer --> */}
         <div className="p-4">
            <h3 className="fw-bold mb-3">Best offers around you</h3>
            <div className="row g-3">
               {/* <!-- 1st product --> */}
               <div className="col-6 col-md-4">
                  <a href="search-list.html" className="text-decoration-none link-dark">
                     <div className="card rounded-4 shadow border-0 overflow-hidden search-list-item">
                        <div className="position-relative">
                           <div className="product-back"><img
                              src="img/1.jpeg"
                              alt="" className="img-fluid rounded-top" /></div>
                           {/* <!-- product time  --> */}
                           <div className="product-time shadow-sm position-absolute bottom-0 end-0 m-2">
                              <span className="badge bg-light text-dark rounded-pill"><i
                                 className="fa-solid fa-stopwatch text-success"></i>&nbsp;37 mins</span>
                           </div>
                           {/* <!-- product off --> */}
                           <div className="product-off bg-danger px-2 py-1 rounded-pill shadow-sm position-absolute top-0 end-0 m-2 small">
                              <div className="d-flex align-items-start gap-1 fw-bold text-white">
                                 <div><i className="fa-solid fa-percent"></i></div>
                                 <div>
                                    <div>50% OFF</div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="card-body">
                           <div className="mb-1">
                              <div><span className="badge bg-success rounded-pill">4.2&nbsp;<i className="fa-solid fa-star"></i></span>
                              </div>
                              <div className="h4 fw-bold m-0">Ginger (Adrak)</div>
                           </div>
                           <div className="d-flex gap-2 text-muted m-0 fw-normal add-footer">
                              <div className="add-btn"><i className="bi bi-plus-lg"></i></div>
                              <div>500g</div>
                              <div>$200</div>
                           </div>
                        </div>
                     </div>
                  </a>
               </div>
               {/* <!-- 2nd product --> */}
               <div className="col-6 col-md-4">
                  <a href="search-list.html" className="text-decoration-none link-dark">
                     <div className="card rounded-4 shadow border-0 overflow-hidden search-list-item">
                        <div className="position-relative">
                           <div className="product-veg bg-success  opacity-75 text-center text-white p-2"><span><i
                              className="fa-brands fa-envira"></i></span>&nbsp;Pure Veg</div>
                           <div className="product-back-two"><img
                              src="img/2.jpeg"
                              alt="" className="img-fluid rounded-top" /></div>
                           {/* <!-- product time  --> */}
                           <div className="product-time shadow-sm position-absolute bottom-0 end-0 m-2">
                              <span className="badge bg-light text-dark rounded-pill"><i
                                 className="fa-solid fa-stopwatch text-success"></i>&nbsp;30 mins</span>
                           </div>
                        </div>
                        <div className="card-body">
                           <div className="mb-1">
                              <div><span className="badge bg-success rounded-pill">4.0&nbsp;<i className="fa-solid fa-star"></i></span>
                              </div>
                              <div className="h4 fw-bold m-0">White Onion</div>
                           </div>
                           <div className="d-flex gap-2 text-muted m-0 fw-normal add-footer">
                              <div className="add-btn"><i className="bi bi-plus-lg"></i></div>
                              <div>250g</div>
                              <div>$80</div>
                           </div>
                        </div>
                     </div>
                  </a>
               </div>
               {/* <!-- 3rd product --> */}
               <div className="col-6 col-md-4">
                  <a href="search-list.html" className="text-decoration-none link-dark">
                     <div className="card rounded-4 shadow border-0 overflow-hidden search-list-item">
                        <div className="position-relative">
                           <div className="product-back"><img
                              src="img/3.jpeg"
                              alt="" className="img-fluid rounded-top" /></div>
                           {/* <!-- product time  --> */}
                           <div className="product-time shadow-sm position-absolute bottom-0 end-0 m-2">
                              <span className="badge bg-light text-dark rounded-pill"><i
                                 className="fa-solid fa-stopwatch text-success"></i>&nbsp;25 mins</span>
                           </div>
                           {/* <!-- product off --> */}
                           <div className="product-off bg-danger px-2 py-1 rounded-pill shadow-sm position-absolute top-0 end-0 m-2 small">
                              <div className="d-flex align-items-start gap-1 fw-bold text-white">
                                 <div><i className="fa-solid fa-percent"></i></div>
                                 <div>
                                    <div>40% OFF</div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="card-body">
                           <div className="mb-1">
                              <div><span className="badge bg-success rounded-pill">4.5&nbsp;<i className="fa-solid fa-star"></i></span>
                              </div>
                              <div className="h4 fw-bold m-0">Celery</div>
                           </div>
                           <div className="d-flex gap-2 text-muted m-0 fw-normal add-footer">
                              <div className="add-btn"><i className="bi bi-plus-lg"></i></div>
                              <div>1kg</div>
                              <div>$100</div>
                           </div>
                        </div>
                     </div>
                  </a>
               </div>
               {/* <!-- 4rth product --> */}
               <div className="col-6 col-md-4">
                  <a href="search-list.html" className="text-decoration-none link-dark">
                     <div className="card rounded-4 shadow border-0 overflow-hidden search-list-item">
                        <div className="position-relative">
                           <div className="product-back"><img
                              src="img/4.jpeg"
                              alt="" className="img-fluid rounded-top" /></div>
                           {/* <!-- product time  --> */}
                           <div className="product-time shadow-sm position-absolute bottom-0 end-0 m-2">
                              <span className="badge bg-light text-dark rounded-pill"><i
                                 className="fa-solid fa-stopwatch text-success"></i>&nbsp;28 mins</span>
                           </div>
                           {/* <!-- product off --> */}
                           <div className="product-off bg-danger px-2 py-1 rounded-pill shadow-sm position-absolute top-0 end-0 m-2 small">
                              <div className="d-flex align-items-start gap-1 fw-bold text-white">
                                 <div><i className="fa-solid fa-percent"></i></div>
                                 <div>
                                    <div>10% OFF</div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="card-body">
                           <div className="mb-1">
                              <div><span className="badge bg-success rounded-pill">4.5&nbsp;<i className="fa-solid fa-star"></i></span>
                              </div>
                              <div className="h4 fw-bold m-0">Tomato Soup</div>
                           </div>
                           <div className="d-flex gap-2 text-muted m-0 fw-normal add-footer">
                              <div className="add-btn"><i className="bi bi-plus-lg"></i></div>
                              <div>500g</div>
                              <div>$100</div>
                           </div>
                        </div>
                     </div>
                  </a>
               </div>
               {/* <!-- 5th product --> */}
               <div className="col-6 col-md-4">
                  <a href="search-list.html" className="text-decoration-none link-dark">
                     <div className="card rounded-4 shadow border-0 overflow-hidden search-list-item">
                        <div className="position-relative">
                           <div className="product-back"><img
                              src="img/5.jpeg"
                              alt="" className="img-fluid rounded-top" /></div>
                           {/* <!-- product time  --> */}
                           <div className="product-time shadow-sm position-absolute bottom-0 end-0 m-2">
                              <span className="badge bg-light text-dark rounded-pill"><i
                                 className="fa-solid fa-stopwatch text-success"></i>&nbsp;25 mins</span>
                           </div>
                           {/* <!-- product off --> */}
                           <div className="product-off bg-danger px-2 py-1 rounded-pill shadow-sm position-absolute top-0 end-0 m-2 small">
                              <div className="d-flex align-items-start gap-1 fw-bold text-white">
                                 <div><i className="fa-solid fa-percent"></i></div>
                                 <div>
                                    <div>40% OFF</div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="card-body">
                           <div className="mb-1">
                              <div><span className="badge bg-success rounded-pill">4.2&nbsp;<i className="fa-solid fa-star"></i></span>
                              </div>
                              <div className="h4 fw-bold m-0">Onion</div>
                           </div>
                           <div className="d-flex gap-2 text-muted m-0 fw-normal add-footer">
                              <div className="add-btn"><i className="bi bi-plus-lg"></i></div>
                              <div>2kg</div>
                              <div>$100</div>
                           </div>
                        </div>
                     </div>
                  </a>
               </div>
               {/* <!-- 6th product --> */}
               <div className="col-6 col-md-4">
                  <a href="search-list.html" className="text-decoration-none link-dark">
                     <div className="card rounded-4 shadow border-0 overflow-hidden search-list-item">
                        <div className="position-relative">
                           <div className="product-back"><img
                              src="img/11.jpeg"
                              alt="" className="img-fluid rounded-top" /></div>
                           {/* <!-- product time  --> */}
                           <div className="product-time shadow-sm position-absolute bottom-0 end-0 m-2">
                              <span className="badge bg-light text-dark rounded-pill"><i
                                 className="fa-solid fa-stopwatch text-success"></i>&nbsp;25 mins</span>
                           </div>
                           {/* <!-- product off --> */}
                           <div className="product-off bg-danger px-2 py-1 rounded-pill shadow-sm position-absolute top-0 end-0 m-2 small">
                              <div className="d-flex align-items-start gap-1 fw-bold text-white">
                                 <div><i className="fa-solid fa-percent"></i></div>
                                 <div>
                                    <div>40% OFF</div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="card-body">
                           <div className="mb-1">
                              <div><span className="badge bg-success rounded-pill">4.1&nbsp;<i className="fa-solid fa-star"></i></span>
                              </div>
                              <div className="h4 fw-bold m-0">Garlic (Lehsun)</div>
                           </div>
                           <div className="d-flex gap-2 text-muted m-0 fw-normal add-footer">
                              <div className="add-btn"><i className="bi bi-plus-lg"></i></div>
                              <div>250g</div>
                              <div>$100</div>
                           </div>
                        </div>
                     </div>
                  </a>
               </div>
            </div>
         </div>
         <div className="pt-4 pb-5"></div>
         {/* <!-- Fixed Bottom --> */}
         <div className="fixed-bottom bg-white shadow px-3 py-2 osahan-nav-bottom">
            <div className="row links">
               <div className="col osahan-nav-bottom-link text-center">
                  <a href="your-order">
                     <span><i className="bi bi-truck h1"></i></span>
                     <p className="m-0">Delivery</p>
                  </a>
               </div>
               <div className="col osahan-nav-bottom-link p-0 text-center active">
                  <a href="/offers">
                     <span><i className="bi bi-percent h1"></i></span>
                     <p className="m-0">Offers</p>
                  </a>
               </div>
               <div className="col osahan-nav-bottom-link osahan-nav-bottom-link-center">
                  <a className="osahan-nav-bottom-home" href="/">
                     <span><i className="bi bi-house-fill h1"></i></span>
                  </a>
               </div>
               <div className="col osahan-nav-bottom-link p-0 text-center">
                  <a href="/cart">
                     <span><i className="bi bi-basket h1"></i></span>
                     <p className="m-0">Cart</p>
                  </a>
               </div>
               <div className="col osahan-nav-bottom-link p-0 text-center">
                  <a href="your-order">
                     <span><i className="bi bi-receipt h1"></i></span>
                     <p className="m-0">History</p>
                  </a>
               </div>
            </div>
         </div>
         {/* <!-- Model --> */}
         {/* <!-- location Modal --> */}
         <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered">
               <div className="modal-content rounded-4 border-0">
                  <div className="modal-header p-4 border-0">
                     <h4 className="modal-title" id="staticBackdropLabel">Add Your Location</h4>
                     <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body pt-0 px-4 pb-4">
                     <form>
                        <div className="input-group border rounded-3 overflow-hidden mb-3">
                           <span className="input-group-text bg-white border-0 px-3 py-2"><i className="bi bi-search"></i></span>
                           <input type="text" className="shadow-none form-control bg-white border-0 ps-0" placeholder="Enter Location" />
                        </div>
                     </form>
                     <div data-bs-dismiss="modal" className="d-flex align-items-center gap-3 mb-3 px-0">
                        <div><i className="fa-solid fa-location-crosshairs text-danger"></i></div>
                        <div>Use Current Location</div>
                     </div>
                     <div className="text-muted text-uppercase small mb-1 pt-4">Search Results</div>
                     <div data-bs-dismiss="modal" className="d-flex align-items-center gap-3">
                        <span><i className="bi bi-search h6"></i></span>
                        <div className="lh-sm border-bottom py-3 w-100">
                           <h6 className="mb-1">Bangalore</h6>
                           <p className="text-muted m-0">Karnataka, India</p>
                        </div>
                     </div>
                     <div data-bs-dismiss="modal" className="d-flex align-items-center gap-3">
                        <span><i className="bi bi-search h6"></i></span>
                        <div className="lh-sm border-bottom py-3 w-100">
                           <h6 className="mb-1">Bangalore internaltional airport</h6>
                           <p className="text-muted m-0">Karmpegowda.in't Airport, Hunachur, karnataka, India</p>
                        </div>
                     </div>
                     <div data-bs-dismiss="modal" className="d-flex align-items-center gap-3">
                        <span><i className="bi bi-search h6"></i></span>
                        <div className="lh-sm border-bottom py-3 w-100">
                           <h6 className="mb-1">Railway Station back gate</h6>
                           <p className="text-muted m-0">M.G. Railway Colony, Majestic, Bangaluru</p>
                        </div>
                     </div>
                     <div data-bs-dismiss="modal" className="d-flex align-items-center gap-3">
                        <span><i className="bi bi-search h6"></i></span>
                        <div className="lh-sm pt-3 w-100">
                           <h6 className="mb-1">Bangalore Cant</h6>
                           <p className="text-muted m-0">Cantonent Railway Station Road, Contonment Railway</p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
}
