import React from 'react'
import './PrivacyPolicy.css'
export default function PrivacyPolicy() {
  return (
    <>
    <div className="osahan-header-main py-3 px-4">
    <div className="d-flex align-items-center">
      <div className="gap-3 d-flex align-items-center">
        <a href="/">
          <i className="bi bi-arrow-left d-flex text-success h3 m-0 back-page"></i>
        </a>
        <h3 className="fw-bold m-0">Privacy Policy</h3>
      </div>
    </div>
  </div>
  <div className="mx-4 mb-4 bg-white rounded-4 shadow overflow-hidden">
    {/* <!-- body --> */}
    <div className="bg-white p-4 border-bottom">
      <div>
        {/* <!-- 1st --> */}
        <div className="mb-3 d-flex gap-3">
         
        <div class="container">
        <h1>Privacy Policy</h1>
        <p>Your privacy is important to us. It is Veggie King's policy to respect your privacy regarding any
            information we may collect from you across our website, <a href="https://www.veggieking.pk">https://www.veggieking.pk</a>, and other sites we own and operate.</p>
        <h2>1. Information We Collect</h2>
        <p>We collect several types of information from and about users of our website, including:</p>
        <ul>
            <li>Personal Data: Name, email address, phone number, shipping address, and payment information when you place an order.</li>
            <li>Usage Data: Information about how you use our website, such as pages visited, time spent on each page, referring/exit pages, and other analytical data.</li>
            <li>Device Information: IP address, browser type, device type, operating system, and other technical information.</li>
            <li>Cookies and Tracking Technologies: We use cookies and similar tracking technologies to track activity on our website and store certain information.</li>
        </ul>
        <h2>2. How We Use Your Information</h2>
        <p>We use the information we collect for various purposes, including:</p>
        <ul>
            <li>To process and fulfill your orders, including payment processing, shipping, and order confirmation.</li>
            <li>To provide customer support and respond to inquiries.</li>
            <li>To improve and optimize our website and user experience.</li>
            <li>To communicate with you about your orders, promotions, and updates.</li>
            <li>To detect, prevent, and address technical issues and security vulnerabilities.</li>
        </ul>
        <h2>3. Data Security</h2>
        <p>We implement security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.</p>
        <h2>4. Third-Party Services</h2>
        <p>We may use third-party services to process payments, fulfill orders, analyze website traffic, and provide other services. These third-party service providers have their own privacy policies governing the use of your information.</p>
        <h2>5. Your Rights and Choices</h2>
        <p>You have the right to access, correct, or delete your personal information. You may also opt-out of certain communications and cookies. Please contact us if you would like to exercise these rights.</p>
        <h2>6. Changes to This Privacy Policy</h2>
        <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
        <h2>7. Contact Us</h2>
        <p>If you have any questions or concerns about our Privacy Policy, please contact us at <a href="mailto:privacy@veggieking.pk">privacy@veggieking.com</a>.</p>
    </div>
        </div>
    </div>
    </div>
  </div>
  </>
  )
}
