import React, { useState } from 'react'
import { signUpSchema } from '../../schema';
import { useFormik } from 'formik';
import './SignUp.css'
import veggieLogo from '../Home/home-logo.png'
import GeneralService from '../../services/general.service';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

export default function SignUp() {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit, resetForm } = useFormik({
    initialValues: {
      name: "",
      address: "",
      phone: "",
      email: "",
      password: "",
    },
    validationSchema: signUpSchema,
    onSubmit: async (values, actions) => {
      try {
        const response = await GeneralService.register(values);
        console.log('Sign up successful', response.data);
        toast.success("Sign up successful!"); // Display success message
        resetForm(); // Reset the form fields
        navigate('/sign-in'); // Navigate to sign-in page
      } catch (error) {
        if(error?.response?.status == 422) {
            if(error?.response?.data?.email) {
              // toast.error(error?.response?.data?.email[0]);
              Swal.fire({
                title: 'Error',
                text: error?.response?.data?.email[0],
                icon: 'warning',
              });
            } else if(error?.response?.data?.phone) {
              // toast.error(error?.response?.data?.phone[0]);
              Swal.fire({
                title: 'Error',
                text: error?.response?.data?.phone[0],
                icon: 'warning',
              });
            }
        } else {
          Swal.fire({
            title: 'Error',
            text: "Sign up failed. Please try again",
            icon: 'warning',
          });
          // toast.error('Sign up failed. Please try again.'); // Show error message
        } 
        // console.error('Sign up failed', error.response ? error.response.data : error.message);
      } finally {
        actions.setSubmitting(false);
      }
    },
  });

  return (
    <div className="container">
      <ToastContainer />
      <div className="logo-container">
        <img src={veggieLogo} alt="Logo" className="logo" />
      </div>
      <div className="signup-container">
        <div className="header">
          <h1>Sign up</h1>
          <p>Please sign up to get started</p>
        </div>
        <form className="signup-form" onSubmit={handleSubmit} noValidate>
          <div className="signup-field">
            <label htmlFor="name">NAME</label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Full Name"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              className={touched.name && errors.name ? 'input-error' : ''}
            />
            {touched.name && errors.name && (
              <div className="error">{errors.name}</div>
            )}
          </div>
          <div className="signup-field">
            <label htmlFor="address">ADDRESS</label>
            <input
              type="text"
              id="address"
              name="address"
              placeholder="Address"
              value={values.address}
              onChange={handleChange}
              onBlur={handleBlur}
              className={touched.address && errors.address ? 'input-error' : ''}
            />
            {touched.address && errors.address && (
              <div className="error">{errors.address}</div>
            )}
          </div>
          <div className="signup-field">
            <label htmlFor="phone">PHONE NO.</label>
            <input
              type="text"
              id="phone"
              name="phone"
              placeholder="Phone No."
              value={values.phone}
              onChange={handleChange}
              onBlur={handleBlur}
              className={touched.phone && errors.phone ? 'input-error' : ''}
            />
            {touched.phone && errors.phone && (
              <div className="error">{errors.phone}</div>
            )}
          </div>
          <div className="signup-field">
            <label htmlFor="email">EMAIL ADDRESS</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Email Address"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              className={touched.email && errors.email ? 'input-error' : ''}
            />
            {touched.email && errors.email && (
              <div className="error">{errors.email}</div>
            )}
          </div>
          <div className="signup-field">
            <label htmlFor="password">PASSWORD</label>
            <div className="password-container">
              <input
                type={passwordVisible ? "text" : "password"}
                id="password"
                name="password"
                placeholder="Password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                className={touched.password && errors.password ? 'input-error' : ''}
              />
              <button
                type="button"
                className="toggle-password"
                onClick={togglePasswordVisibility}
              >
                {passwordVisible ? '🙈' : '👁️'}
              </button>
              {touched.password && errors.password && (
                <div className="error">{errors.password}</div>
              )}
            </div>
          </div>
          <div className="signup-field">
            <button type="submit" className="signup-button">Sign Up</button>
          </div>
        </form>
        {successMessage && <div className="success-message">{successMessage}</div>}
      </div>
    </div>
  );
}