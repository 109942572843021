import React, { useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import GeneralService from '../../services/general.service';

export default function OtpVerify() {
    const location = useLocation();
    const { email } = location.state || {};
    const [otpFields, setOtpFields] = useState(['', '', '', '']);
    const navigate = useNavigate();
    const inputRefs = useRef([]);

    const handleInputChange = (text, index) => {
        const newOtpFields = [...otpFields];
        newOtpFields[index] = text;
        setOtpFields(newOtpFields);

        // If backspace is pressed and the field is empty, move focus to the previous field
        if (text.length === 0 && index > 0) {
            inputRefs.current[index - 1].focus();
        }

        // If backspace is pressed and the field is empty, clear the previous field's value
        if (text.length === 0 && index > 0 && newOtpFields[index - 1] === '') {
            const updatedOtpFields = [...otpFields];
            updatedOtpFields[index - 1] = '';
            setOtpFields(updatedOtpFields);
        }

        // If a digit is entered and the field is not the last one, move focus to the next field
        if (text.length === 1 && index < otpFields.length - 1) {
            inputRefs.current[index + 1].focus();
        }
    };

    const handleVerifyOTP = async () => {
        const otp = otpFields.join('');
        // Here you can implement your OTP verification logic
        // For demonstration, I'm just displaying the entered OTP in an alert
        // Alert.alert('OTP Error', "Enter valid OTP");
        try {
            const response = await GeneralService.checkForgotToken(otp, email); // Call the forgot method and capture the response
            navigate("/reset-password", { state: { otp: otp, email: email } });
        } catch (err) {
            console.log(err);
            if (err?.response?.status == 404) {
                toast.error("Incorrect otp");
            } else {
                toast.error("Server error. Please try again later.");
            }
        }
    };

    const handleForgot = async () => {
        try {
            const response = await GeneralService.forgotOtp(email); // Call the forgot method and capture the response
            console.log('Forgot response:', response); // Log the response to see the structure and extract the message if needed
            toast.success("Code resent.");

            // navigate("/opt", { state: { email: email } });
        } catch (err) {
            // console.error('Error occurred:', err);
            toast.error("Server error. Please try again later.");
        }
    };

    return (
        <>
            <div className="osahan-header-main sticky-top py-3 px-4">
                <div className="d-flex align-items-center">
                    <div className="gap-3 d-flex align-items-center">
                        <Link to="/forgot-password"><i className="bi bi-arrow-left d-flex text-success h3 m-0 back-page"></i></Link>
                        <h3 className="fw-bold m-0">OTP Verification</h3>
                    </div>
                    {/* <div className="ms-auto gap-2 d-flex align-items-center">
                        <a href="notifications.html" className="link-dark"><i
                            className="bi bi-bell-fill d-flex m-0 h3 header-bell"></i></a>
                        <a className="toggle osahan-toggle text-dark m-0" href="#"><i
                            className="bi bi-list d-flex m-0 h3 header-list"></i></a>
                    </div> */}
                </div>
            </div>
            {/* //
    <!-- Body --> */}
            <div className="mx-4 mb-4 bg-white rounded-4 shadow overflow-hidden">
                <div className="text-center my-5 h5">
                    <div className="fw-normal text-muted">We have sent a verification code to</div>
                    <div className="fw-bold mt-2 h3">{email}</div>
                </div>
                <div className="text-center px-5">
                    <div style={{ display: 'flex' }}>
                        {otpFields.map((field, index) => (
                            <div key={index}>
                                <input
                                    className="shadow-none form-control px-0 text-center py-3"
                                    type="text"
                                    maxLength={1}
                                    value={field}
                                    onChange={(event) => handleInputChange(event.currentTarget.value, index)}
                                    ref={(ref) => (inputRefs.current[index] = ref)}
                                    aria-label="default input example"
                                />
                            </div>
                        ))}
                    </div>
                    <div className="mb-5"></div>

                    <button type="button" className="btn btn-outline-secondary w-50 btn-sm" onClick={handleForgot}>Resend Code</button>

                    <div className="mb-5">
                        {/* <a href="#" className="text-decoration-none link-danger">Try other login methods</a> */}
                    </div>
                </div>
            </div>
            {/*
    <!-- fixed bottom --> */}
            <div className="fixed-bottom d-flex justify-content-center p-4">
                <button onClick={() => { handleVerifyOTP() }} className="btn btn-success btn-lg d-grid rounded-pill">Confirm</button>
            </div>
        </>
    )
}