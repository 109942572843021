// illustrations.js
export const illustrations = {
    received: '../assets/illustrations/received.gif',
    deliverd: '../assets/illustrations/deliverd.gif',
    packing: '../assets/illustrations/packing.gif',
    thnkyou: '../assets/illustrations/thnkyou.gif',
};

export const COLORS = {
    red: '#ff0000',
};
