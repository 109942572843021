import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Footer from '../Includes/Footer'
import GeneralService from '../../services/general.service';

export default function RiderUserDetails() {
   const location = useLocation();
   const { custName, custPhone, custAddress } = location.state || {};

   return (
      <>
         <div className="osahan-header-main py-3 px-4">
            <div className="d-flex align-items-center">
               <div className="gap-3 d-flex align-items-center">
                  <Link to="/rider-orders">
                     <i className="bi bi-arrow-left d-flex text-success h3 m-0 back-page"></i>
                  </Link>
                  <h3 className="fw-bold m-0">User Details</h3>
               </div>
            </div>
         </div>
         <div className="mx-4 mb-3 bg-white rounded-4 shadow p-4">
            <div className="m-0">
               <div className="d-flex align-items-center gap-3 mb-3">
                  <div className="lh-sm flex-grow-1">
                     <div className="text-muted fw-normal">
                        Name: {custName}
                     </div>
                  </div>
                  <div className="lh-sm flex-grow-1">
                     <div className="text-muted fw-normal">
                        Phone: {custPhone}
                     </div>
                  </div>
                  <div className="lh-sm flex-grow-1">
                     <div className="text-muted fw-normal">
                        Delivery Address: {custAddress}
                     </div>
                  </div>
               </div>
            </div>
         </div>

         {/* <div className="pb-5 pt-5"></div> */}
         {/* <Footer /> */}
      </>
   )
}
